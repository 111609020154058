import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import TermsHeader from "./TermsHeader";
import { Spinner } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";

export default function Downloads() {

    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");

    const [downloads, setDownloads] = useState({ data: undefined, isFetching: false });

    useEffect(() => {
        const fetchDownloads = async () => {
            const url = `${process.env.REACT_APP_PUBLIC_DOWNLOADS}?lng=${lng}`
            try {
                setDownloads({ data: downloads.data, isFetching: true });
                const response = await axios.get(`${url}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

                setDownloads({ data: response.data.data, isFetching: false });
            } catch (exception) {
                setDownloads({ data: [], isFetching: false });
                console.error('Error al realizar la solicitud:', exception);
            }
        };

        fetchDownloads();
    }, [lng]);

    const getTypeStyle = (typeId) => {
        switch (typeId) {
            case 1:
                return {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" width="20" height="20" strokeWidth="1">
                        <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                        <path d="M8 4l0 16" />
                        <path d="M16 4l0 16" />
                        <path d="M4 8l4 0" />
                        <path d="M4 16l4 0" />
                        <path d="M4 12l16 0" />
                        <path d="M16 8l4 0" />
                        <path d="M16 16l4 0" />
                    </svg>, bgcolor: '#8bc8df', color: "white"
                };
                break;
            case 2:
                return {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" width="20" height="20" strokeWidth="1">
                        <path d="M19 4v16h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12z"></path>
                        <path d="M19 16h-12a2 2 0 0 0 -2 2"></path>
                        <path d="M9 8h6"></path>
                    </svg>, bgcolor: '#f3b572', color: "white"
                };
            default:
                return {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" width="20" height="20" strokeWidth="1">
                        <path d="M20 6v12a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2z" />
                        <path d="M10 16h6" />
                        <path d="M13 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                        <path d="M4 8h3" />
                        <path d="M4 12h3" />
                        <path d="M4 16h3" />
                    </svg>, bgcolor: '#b3d5a7', color: "white"
                };
        }
    }

    return (<>
        {!downloads.data && downloads.isFetching &&
            <div className="flex justify-center mt-4 mb-4">
                <Spinner animation="border" variant="dark"></Spinner>
                {t("glossary.charging")}
            </div>
        }

        {downloads.data && !downloads.isFetching &&
            <div className="max-w-6xl mx-auto px-4 sm:px-6 m-4" data-aos="fade-down" data-aos-delay="200">
                {downloads.data.length > 0 &&
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {downloads.data.map((download, index) => {
                            const { icon, bgcolor, color } = getTypeStyle(download.typeId);
                            return (
                                <div key={index} className={` h-auto flex flex-col relative success-slide`}>
                                    <div className="relative w-full h-48 bg-cover bg-center success-slide-img"
                                        style={{ backgroundImage: `url(${download.imageUrl})`, height:"200px", backgroundSize:"cover" }}
                                    >
                                        <HashLink
                                            to={`/resources/download/${download.slug}`}
                                            className="text-sm text-airco2-principal flex items-center"
                                            smooth={true}
                                            offset={50}
                                            duration={500}>
                                        </HashLink>
                                        <div className='absolute shadow flex items-center justify-center  px-3 text-sm '
                                            style={{ minWidth: "100px", height: '45px', top: '5px', right: '5px', borderRadius: '1rem', backgroundColor: bgcolor, color: color }} >
                                            <span className="mr-1">{icon}</span> {download.typeName}
                                        </div>
                                    </div>
                                    <div className=" p-3 pb-4 swiper-body text-airco2-general-grey">
                                        <HashLink
                                            to={`/resources/download/${download.slug}`}
                                            className="text-sm text-airco2-principal flex items-center"
                                            style={{ minHeight: "70px" }}
                                            smooth={true}
                                            offset={50}
                                            duration={500}>
                                            <span className={`block font-bold text-lg py-2 `}>{download.title}</span>
                                        </HashLink>
                                        <div className="text-base	">
                                            <p dangerouslySetInnerHTML={{ __html: download.summary }} />
                                        </div>
                                    </div>
                                </div>)
                        })
                        }
                    </div>}
            </div>}

    </>)
}