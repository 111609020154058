import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function ColumnMapping({ view, userColumns, predefinedColumns, onMappingSubmit, userFirstLineData, inputOptions, predefinedColumnsRequired, terceros }) {

  const [t, i18n] = useTranslation("global");
  const [showSpinner, setShowSpinner] = useState(false);

  let columnMappings = predefinedColumns.map(column => ({
    name: column,
    index: null
  }))

  const handleMappingChange = (e, index) => {
    const selectedColumn = e.target.value;
    if (selectedColumn != "") {
      columnMappings[index] = JSON.parse(selectedColumn);
    }
  }

  const handleSubmit = () => {
    onMappingSubmit(columnMappings);
  }

  const hanldeReset = () => {
    onMappingSubmit([]);
  }

  return (
    <div className="flex justify-center">
      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        <table className={` ${terceros ? 'sec-neutro-background' : 'bg-white'} text-sm table-auto self-center`}>
          {/* <thead>
            <tr>
              <td className='sec-grey-text'>{t("footPrint.cabecerasOk")}:</td>
              <td className='sec-grey-text'>{t("footPrint.tusCabeceras")}:</td>
            </tr>
          </thead> */}
          <tbody>
            {predefinedColumns.map((col, index) => {
              const matchedColumnIndex = userColumns.findIndex(
                (userCol) => userCol === col || userCol.includes(col) || col.includes(userCol)
              );

              const defaultValue =
                matchedColumnIndex !== -1
                  ? JSON.stringify({
                    name: col,
                    index: matchedColumnIndex,
                  })
                  : "";

              // Si hay un valor por defecto, invoca handleMappingChange
              if (defaultValue) {
                handleMappingChange(
                  { target: { value: defaultValue } }, // Simula el evento
                  index
                );
              }

              return (
                <tr key={index} >
                  <td className='pt-4 pr-4'>
                    <label className=''>{col}:
                      {predefinedColumnsRequired.includes(col) && <span className="text-red-500">*</span>}
                    </label>
                  </td>
                  <td className='pt-4 pr-4'>
                    <select className='text-sm' style={{ maxWidth: '300px' }}
                      onChange={(e) => handleMappingChange(e, index)}
                      required={predefinedColumnsRequired.includes(col)}
                      defaultValue={defaultValue}
                    >
                      <option value="">{t("footPrint.cabeceraAdecuada")}</option>
                      {userColumns.map((userCol, i) => (
                        <option key={i} value={JSON.stringify({ name: col, index: i })} style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {userCol}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className='flex '>
          <button className='mt-4 m-4 btn-sm btn-banner-o w-1/4' type="submit">{t("fpThird.continuar")}</button>
        </div>

      </form>
    </div >

  );
}

export default ColumnMapping;