import Papa from 'papaparse';
import React, { useCallback, useEffect, useState } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from "react-bootstrap/Tooltip";
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import info from "../../../images/info.gif";

export default function ImportExcelUploadFileOrData({ excelHeaders, handleGetExcelData }) {
    const [t, i18n] = useTranslation("global");

    const [inputTitles, setInputTitles] = useState([]);
    const [excelError, setExcelError] = useState('');
    const [excelTextAreaError, setExcelTextAreaError] = useState('');
    const [workbook, setWorkbook] = useState(null);
    const [sheetNames, setSheetNames] = useState([]);
    const [selectedSheet, setSelectedSheet] = useState('');
    const [fileName, setFileName] = useState('');
    const [showSheetSelector, setShowSheetSelector] = useState(false);


    useEffect(() => {
        setInputTitles(excelHeaders);
    }, [excelHeaders])


    //Evento para crear  un array con la data del Excel 
    const handleFile = (file) => {

        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type !== 'application/vnd.ms-excel' && file.type !== 'text/csv') {
            setExcelError(t("exceltransactions.checkFile"));
            setTimeout(() => {
                setExcelError('');
            }, 3000);
            return;
        } else if (file.type == 'text/csv') {
            Papa.parse(file, {
                complete: (result) => {
                    handleGetExcelData(result.data);
                    setExcelError('');
                    setExcelTextAreaError('')
                },
                error: (error) => {
                    console.error('Error parsing CSV file:' + error);
                    handleGetExcelData([]);
                },
            });
        } else {
            setFileName(file.name)
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const wb = XLSX.read(data, { type: 'array' });
                setWorkbook(wb);
                setSheetNames(wb.SheetNames);
                if (wb.SheetNames.length == 1) {
                    setSelectedSheet(wb.SheetNames[0]);
                    loadSheetData(wb, wb.SheetNames[0], true);
                } else {
                    setShowSheetSelector(true);
                }
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const loadSheetData = (workbook, sheetName, confirmedSheet) => {
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Process the data to handle empty cells
        const processedData = jsonData.map(row => {
            const maxLength = Math.max(...jsonData.map(r => r.length));
            return Array.from({ length: maxLength }, (_, i) => row[i] !== undefined ? row[i] : "");
        })
            .filter(row => row.some(cell => cell !== ""));


        if (confirmedSheet) {
            handleGetExcelData(processedData);
            setExcelError('');
            setExcelTextAreaError('');
        } else {
            setPreviewData(processedData);
            setExcelError('');
            setExcelTextAreaError('');
        }

    };

    const handleSheetData = (data) => {
        handleGetExcelData(data);
        setShowSheetSelector(false);
        setExcelError('');
        setExcelTextAreaError('');
    }

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        handleFile(file);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop
    });



    const handlePaste = (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('text');
        // Check if text contains tabs
        if (pastedData.includes('\t')) {
            handlePasteExcel(pastedData);
        } else if (pastedData.includes(',') || pastedData.includes(';')) {
            handlePasteCsv(pastedData);
        } else {
            setExcelTextAreaError(t("footPrint.modalExcelErrorTextArea"));
            setTimeout(() => {
                setExcelTextAreaError('');
            }, 5000);
        }
    };

    const handlePasteCsv = (pastedData) => {
        const rows = pastedData.split('\n');
        const parsedData = rows.map(row => {
            const columns = [];
            let currentColumn = '';
            let inQuotes = false;

            for (let i = 0; i < row.length; i++) {
                const char = row[i];
                if (char === '"') {
                    inQuotes = !inQuotes;
                } else if (char === ',' && inQuotes) {
                    currentColumn += '.';
                } else if (char === ',') {
                    columns.push(currentColumn);
                    currentColumn = '';
                } else {
                    currentColumn += char;
                }
            }
            columns.push(currentColumn.trim());
            return columns;
        });

        handleGetExcelData(parsedData);
        setExcelError('');
        setExcelTextAreaError('');
    };

    const handlePasteExcel = (pastedData) => {
        const rows = pastedData.split('\n').map(row => {
            const columns = row.split('\t');
            return columns.map(column => {
                // Verifica si el valor es un número con coma decimal
                if (!isNaN(column.replace(',', '.'))) {
                    return column.replace(/,/g, '.').trim();
                }
                // Si no es un número, devuelve el texto tal cual
                return column.trim();
            });
        });
        handleGetExcelData(rows);
        setExcelError('');
        setExcelTextAreaError('');
    };


    const renderTooltipInfo = (props) => (
        <Tooltip id="toolTip-info" className="tooltip-grande" {...props}>
            <img src={info} alt="gif-info" />
        </Tooltip>
    );

    return <>
        {showSheetSelector && workbook &&
            <ExcelSheetSelector sheetNames={sheetNames} workbook={workbook} fileName={fileName} handleSheetData={handleSheetData} t={t} />}

        {!showSheetSelector && !workbook && <div className='flex flex-col my-2 text-sm' >
            <p className="mb-2">{t("footPrint.modalExcelCargaArchivo")}</p>
            <div {...getRootProps()} style={{ border: isDragActive ? '3px dashed #f19b7f' : '3px dashed #878787', padding: '20px', textAlign: 'center', height: "250px", borderRadius: "10px", alignContent: "center" }} className="mb-4">
                <input {...getInputProps({ accept: '.xlsx, .xls, .csv' })} />
                {
                    isDragActive ?
                        <p>{t("footPrint.sueltaArchivo")} ...</p> :
                        <div className="alig-center">
                            <div className=" flex justify-center items-center gap-4 mb-2 ">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-type-csv" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#878787" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                    <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
                                    <path d="M7 16.5a1.5 1.5 0 0 0 -3 0v3a1.5 1.5 0 0 0 3 0" />
                                    <path d="M10 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75" />
                                    <path d="M16 15l2 6l2 -6" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-type-xls" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#878787" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                    <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
                                    <path d="M4 15l4 6" />
                                    <path d="M4 21l4 -6" />
                                    <path d="M17 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75" />
                                    <path d="M11 15v6h3" />
                                </svg>
                            </div>
                            <p style={{ color: '#878787' }} className="font-semibold">{t("footPrint.arrastraOclick")}</p>
                        </div>
                }
                {excelError && <p style={{ color: 'red' }}>{excelError}</p>}
            </div>

            <span className="flex gap-1 items-center my-2">
                <p>{t("footPrint.modalExcelPegaDatos")}</p>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipInfo}
                    style={{ width: '650px', zIndex: "500" }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-circle" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                        <path d="M12 9h.01" />
                        <path d="M11 12h1v4h1" />
                    </svg>
                </OverlayTrigger>

            </span>
            <p className="flex gap-1 items-center">
                <span className='font-semibold'>{t("footPrint.cabeceras")}: </span>
                <span>{inputTitles.join(", ")}</span>
            </p>
            <textarea
                onPaste={handlePaste}
                onKeyDown={(e) => {
                    if (!(e.ctrlKey && e.key === 'v')) {
                        e.preventDefault();
                    }
                }}
                className='w-full mt-2'
                style={{ border: '2px solid #878787' }}
                placeholder="Pega aquí los datos incluyendo las cabeceras" />
            {excelTextAreaError && <p style={{ color: 'red', textAlign: 'center' }}>{excelTextAreaError}</p>}
        </div>}
    </>
}


// Componente Hijo para la selección de hojas y previsualización
const ExcelSheetSelector = ({ sheetNames, workbook, fileName, handleSheetData, t }) => {
    const [selectedSheet, setSelectedSheet] = useState('');
    const [previewData, setPreviewData] = useState([]);


    const loadSheetData = (workbook, sheetName, confirmedSheet) => {
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const processedData = jsonData.map(row => {
            const maxLength = Math.max(...jsonData.map(r => r.length));
            return Array.from({ length: maxLength }, (_, i) => row[i] !== undefined ? row[i] : "");
        })
            .filter(row => row.some(cell => cell !== ""));

        if (confirmedSheet) {
            handleSheetData(processedData);
        } else {
            setPreviewData(processedData);
        }
    };

    const handleSheetChange = (event) => {
        const sheetName = event.target.value;
        setSelectedSheet(sheetName);
        loadSheetData(workbook, sheetName, false);
    };

    const handleConfirmSheet = () => {
        loadSheetData(workbook, selectedSheet, true);
    };


    return (
        <div>
            {sheetNames.length > 1 && (
                <form onSubmit={handleConfirmSheet}>
                    <div className="flex flex-col gap-2 justify-center items-center my-4 text-sm">

                        <label htmlFor="sheetSelect">{t("footPrint.modalExcelSelectHoja") + " " + fileName}: </label>
                        <select id="sheetSelect" className="text-sm" defaultValue={selectedSheet} onChange={handleSheetChange} required>
                            <option value="">{t("footPrint.selecciona")}</option>
                            {sheetNames.map((name) => (
                                <option key={name} value={name}>
                                    {name}
                                </option>
                            ))}
                        </select>
                        <button className="btn-sm btn-banner-o rounded-md mt-2" type="submit" >
                            {t("fpThird.continuar")}
                        </button>
                    </div>
                </form>

            )}
            {(selectedSheet && previewData.length > 0) &&
                <div>
                    <div className="flex justify-between text-sm mb-4 sec-text-neutro">
                        <span className="ml-2 ">{t("footPrint.modalExcelSelectHojaPreview")} <i>{selectedSheet}</i></span>
                        <span className="italic">{t("footPrint.mostrando") + (previewData.length > 10 ? "10" : previewData.length) + t("footPrint.de") + previewData.length + t("footPrint.lineas")}</span>
                    </div>
                    <div className='table-responsive'>
                        <table className="border-collapse border text-xs table-auto w-full">
                            <tbody className="divide-y text-center divide-gray-200">
                                {previewData.map((row, rowIndex) => {
                                    if (rowIndex > 10) {
                                        return;
                                    } else if (rowIndex == 0) {
                                        return (
                                            <tr className="sec-green-background" key={rowIndex}>
                                                {row.map((cell, cellIndex) => (
                                                    <td className="border" key={cellIndex}>{cell}</td>
                                                ))}
                                            </tr>
                                        )
                                    } else {
                                        return (<tr key={rowIndex}>
                                            {row.map((cell, cellIndex) => (
                                                <td className="border" key={cellIndex}>{cell}</td>
                                            ))}
                                        </tr>)
                                    }
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>}
        </div>
    );
}