import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IdiomContext } from '../../context/createcontext';
import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import SuccessStories from './componentsHome/SuccessStories';
import { clientsData } from "./dataClientes/clientes";
const ClientDetailPage = (props) => {

  const pathParams = useParams();
  const name = pathParams.name;
  const clientByUrl = clientsData.find((element) => element.urlName == name);

  if(clientByUrl == undefined) {
    window.location.href = `${process.env.REACT_APP_URL}/notfound`;
  }

  const {
    clientName,
    heroTitle,
    heroDescription,
    heroImageUrl,
    logo,
    features,
    actions,
    problem,
    solution,
    results,
    testimonial,
    testimonialImg,
    testimonialName
  } = { ...clientByUrl, ...props };

  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");

  const getIcon = (icon) => {
    switch (icon) {
      case "check":
        return <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.7" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
          <path d="M9 12l2 2l4 -4" />
        </svg>;
      case "chart":
        return <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chart-bar" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.7" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M3 12m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
          <path d="M9 8m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
          <path d="M15 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
          <path d="M4 20l14 0" />
        </svg>;
      case "leaf":
        return <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-leaf" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.7" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5 21c.5 -4.5 2.5 -8 7 -10" />
          <path d="M9 18c6.218 0 10.5 -3.288 11 -12v-2h-4.014c-9 0 -11.986 4 -12 9c0 1 0 3 2 5h3z" />
        </svg>;
      case "electricity":
        return <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bolt" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.7" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11" />
        </svg>
      default:
        return null;
    }
  };
  return (
    <div>

      <Header></Header>
      {/* Hero Section */}
      <section className="bg-white py-5 text-airco2-general-grey ">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-5 pb-5 mt-5 flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h1 className="h1 md:text-5xl font-bold mb-4">{t(heroTitle)}</h1>
            <p className="text-xl text-gray-600 mb-6">{t(heroDescription)}</p>
          </div>
          <div className="md:w-1/2 pl-2">
            <img
              src={heroImageUrl}
              alt={clientName}
              className="rounded-lg shadow-lg w-full h-auto"
              style={{ width: '500px' }}
            />
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-5 bg-green-semitr">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 ">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="text-center ">
                <div className="mb-4 inline-flex items-center justify-center text-airco2-general-grey gap-3 text-3xl font-bold">
                  <div className="flex justify-center">{getIcon(feature.icon)}</div>
                  <span>{feature.number}</span>
                </div>
                <p className="text-gray-600">{t(feature.text)}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Problem/Solution Section */}
      <section className="py-5 bg-gray-50 text-airco2-general-grey">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 ">
          <div className="flex flex-col md:flex-row gap-8">
            <div className="width-33percent client-actions-sticky" >
              <div className="border rounded-lg p-6 bg-white shadow-md relative">
                <div className='bg-white w-full px-1' style={{ height: '80px' }} >
                  <div className='w-full h-full' style={{ backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }} />
                </div>
                <h2 className="text-2xl font-bold mb-4">{t("clientes.actions")}</h2>
                <div>
                  <ul className="ml-3">
                    {actions.map((action, index) => (
                      <li className="mb-2" key={index} style={{ listStyle: 'disc' }}>
                        {t(action)}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="width-66percent">
              <h2 className="text-2xl font-bold mb-4">{t("clientes.problem")}</h2>
              <p className="text-gray-600 mb-8">{t(problem)}</p>
              <h2 className="text-2xl font-bold mb-4">{t("clientes.solution")}</h2>
              <p className="text-gray-600 mb-8">{t(solution)}</p>
              <h2 className="text-2xl font-bold mb-4">{t("clientes.results")}</h2>
              <p className="text-gray-600">{t(results)}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonial Section */}
      <section className="py-5 bg-white text-airco2-general-grey ">
        <div className="max-w-6xl mx-auto px-4 sm:px-6  text-center">
          <div className=" flex flex-col md:flex-row lg:flex items-center lg:space-y-0 lg:space-x-8 text-center lg:text-left space-between gap-2 text-airco2-general-grey ">
            <div className="shrink-0 relative inline-flex img-txt-hidden">
              <img className="img-community" src={testimonialImg}
                alt="logo" />
              <div className="absolute " style={{ bottom: "0px", right: "-40px" }}>
                <a className="flex items-center sec-green-background font-cabinet-grotesk text-xs text-white decoration-2 underline-offset-2 hover:underline px-3 py-1 rounded-full shadow-sm" href="#0">
                  <span>{testimonialName + clientName}</span>
                </a>
              </div>
            </div>
            <div className="relative">
              <blockquote className="text-lg  italic font-cabinet-grotesk mb-4">"{t(testimonial)}"</blockquote>
            </div>
          </div>
        </div>
      </section>

      <SuccessStories></SuccessStories>

      <Footer></Footer>
    </div>
  );
};

export default ClientDetailPage;
