import React from "react";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import gpTableData from '../../intranet/data/gp_datos_tabla.json';
import { formatThousands } from "../../intranet/utils/Utils";

export default function tableGreatPackaging() {
  const [t, i18n] = useTranslation("global");
  const cert = process.env.REACT_APP_URL + '/pdf/applus_certificado_gp.pdf';  
  
  const [tableData, setTableData] = useState(gpTableData);

  // const [currentPage, setCurrentPage] = useState(1);
  // const rowsPerPage = 2;

  // // Calcular índices para la paginación
  // const indexOfLastRow = currentPage * rowsPerPage;
  // const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  // const currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

  // // Total de páginas
  // const totalPages = Math.ceil(tableData.length / rowsPerPage);

  // // Funciones para cambiar de página
  // const nextPage = () => {
  //   if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  // };

  // const prevPage = () => {
  //   if (currentPage > 1) setCurrentPage(currentPage - 1);
  // };

  return (
    <div className="table-responsive rounded-lg my-3">
      {/* <table className=" table table-dark table-striped  bg-white text-xs table-auto w-full "> */}
      <table className=" table bg-white table-auto w-full gp-table-text">
        <thead className="border-b">
          <tr className="text-gp-green ">
            <th className="pl-2 first:pl-5 last:pr-5 pl-3 py-3 text-center" style={{ minWidth: '135px' }} >{t("gp.block4.table.th1")}</th>
            <th className="pl-2 first:pl-5 last:pr-5 pl-3 py-3 text-center" >{t("gp.block4.table.th2")}</th>
            <th className="pl-2 first:pl-5 last:pr-5 pl-3 py-3 text-center" >SKU</th>
            <th className="pl-2 first:pl-5 last:pr-5 pl-3 py-3 text-center" >{t("gp.block4.table.th3")}</th>
            <th className="pl-2 first:pl-5 last:pr-5 pl-3 py-3 text-center" >{t("gp.block4.table.th8")}</th>
            <th className="pl-2 first:pl-5 last:pr-5 pl-3 py-3 text-center" >{t("gp.block4.table.th4")}</th>
            <th className="pl-2 first:pl-5 last:pr-5 pl-3 py-3 text-center" >{t("gp.block4.table.th5")}</th>
            <th className="pl-2 first:pl-5 last:pr-5 pl-3 py-3 text-center" >{t("gp.block4.table.th7")}</th>
            <th className="pl-2 first:pl-5 last:pr-5 pl-3 py-3 text-center" >{t("gp.block4.table.th6")}</th>
          </tr>
        </thead>
        <tbody className="text-gp-lightgrey">
          {tableData.map((row, index) => (
            <tr key={index}>
              <td className="text-center items-center">{row.Lote}</td>
              <td className="text-center">{row.Fecha}</td>
              <td className="text-center">{row.SKU}</td>
              <td className="text-center">{formatThousands(row.Cantidad)}</td>
              <td className="text-center">{formatThousands(row.ToneladasEmitidas)}</td>
              <td className="text-center">{formatThousands(row.ToneladasCompensadas)}</td>
              <td className="text-center">
                <a
                  href='https://storage.googleapis.com/airco2-documents/VerrayBAM_Cencosud.pdf'
                  target="_blank"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://storage.googleapis.com/airco2-documents/VerrayBAM_Cencosud.pdf', "_blank");
                  }}
                >BAM Madre de Dios Amazon REDD+ Project</a>
              </td>
              <td className="text-center"><a href="https://registry.verra.org/app/projectDetail/VCS/868" target="_blank">{row.IDProyecto}</a></td>
              <td className="text-center"><a href='https://storage.googleapis.com/airco2-documents/20240207_CASTANEROS_ESP_VF.pdf' target="_blank">{t("gp.block4.table.tdBosques")}</a></td>
            </tr>
          )
          )}
        </tbody>
      </table>

      {/* <div className="flex justify-center gap-4" style={{ marginTop: '10px' }}>
        {currentPage > 1 &&
          <button onClick={prevPage} disabled={currentPage === 1}>
            {t("fpThird.preguntaAnterior")}
          </button>
        }
        <span style={{ margin: '0 10px' }}>
          {t("gp.block4.table.pagina")}<span className="text-gp-green font-bold">{currentPage}</span>{t("gp.block4.table.de")}{totalPages}
        </span>
        {currentPage < totalPages &&
          <button onClick={nextPage} disabled={currentPage === totalPages}>
            {t("fpThird.siguientePregunta")}
          </button>
        }
      </div> */}

    </div>
  );
}
