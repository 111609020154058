import Header from '../../partials/Header';

function HuellaCarbono() {
    return (
        <>
            <div className="hidden">
                <Header></Header>
            </div>
            <iframe
                src="/huella-carbono.html"
                style={{
                    width: "100%",
                    height: "100vh",
                    border: "none",
                    display: "block",
                }}
            />
        </>
    );
}
export default HuellaCarbono;