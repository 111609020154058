import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import UserService from '../../../services/UserService';
import axios from 'axios';

function SettingsSidebar() {

  const location = useLocation();
  const { pathname } = location;
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");

  const [urlStripeCustomer, setUrlStripeCustomer] = useState();

  useEffect(()=>{
    const fetchPlans = async () => {
      const url = `${process.env.REACT_APP_CH_STRIPE_CUSTOMER_PORTAL}`;
      try {
        const response = await axios.get(`${url}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${UserService.getToken()}`
            }
        });
        setUrlStripeCustomer(response.data.data);
      } catch (exception) {
        console.error(exception)
      }
    };
    fetchPlans();
  },[])



  return (
    <div className="sec-neutro-background flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-gray-200 min-w-60 md:space-y-3">
      {/* Group 1 */}
      <div>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-3">{t("account.configuracion")}</div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink to="/account/account" className={`flex text-sidebar items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/account/account') && 'bg-white'}`}>
              <svg xmlns="http://www.w3.org/2000/svg" className={`shrink-0 text-gray-400 mr-2 ${pathname.includes('/account/account') && 'text-indigo-400'}`} width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke={` ${pathname.includes('/account/account')  ? 'var(--airco2-orange-color)' : '#64748b'} `} fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/account/account') ? 'text-airco2-secondary' : 'hover:text-gray-700'}`}>{t("account.mi-cuenta")}</span>
            </NavLink>
          </li>

          {/* CUSTOMER-PROFILE STRIPE */}
          {(UserService.getUserType() === 'Representante empresarial' && UserService.getOriginData() === 'Formulario') &&
          <>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <a className={`flex text-sidebar items-center justify-start px-2.5 py-2 rounded whitespace-nowrap`} href={ urlStripeCustomer} target='_blank'>  
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-stripe mr-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke={` ${pathname.includes('/account/stripe')  ? 'var(--airco2-orange-color)' : '#64748b'} `} fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M11.453 8.056c0 -.623 .518 -.979 1.442 -.979c1.69 0 3.41 .343 4.605 .923l.5 -4c-.948 -.449 -2.82 -1 -5.5 -1c-1.895 0 -3.373 .087 -4.5 1c-1.172 .956 -2 2.33 -2 4c0 3.03 1.958 4.906 5 6c1.961 .69 3 .743 3 1.5c0 .735 -.851 1.5 -2 1.5c-1.423 0 -3.963 -.609 -5.5 -1.5l-.5 4c1.321 .734 3.474 1.5 6 1.5c2 0 3.957 -.468 5.084 -1.36c1.263 -.979 1.916 -2.268 1.916 -4.14c0 -3.096 -1.915 -4.547 -5 -5.637c-1.646 -.605 -2.544 -1.07 -2.544 -1.807z" />
              </svg>
              <span className={`text-sm font-medium hover:text-gray-700 ${pathname.includes('/account/stripe')  && 'text-airco2-secondary font-semibold' }`}>Mi suscripción</span>
            </a>
          </li> 

          <li className="mr-0.5 md:mr-0 md:mb-0.5">
          <NavLink to="/account/plans" className={`flex  text-sidebar items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/account/plans') && 'bg-white'}`}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler mr-2 icon-tabler-report-money" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke={` ${pathname.includes('/account/plans')  ? 'var(--airco2-orange-color)' : '#64748b'} `} fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                <path d="M14 11h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" />
                <path d="M12 17v1m0 -8v1" />
            </svg>
            {/* <svg className={`w-4 h-4 shrink-0 fill-current text-gray-400 ml-1 mr-2 ${pathname.includes('account/plans') && 'text-indigo-400'}`} viewBox="0 0 16 16">
              <path d="M5 9h11v2H5V9zM0 9h3v2H0V9zm5 4h6v2H5v-2zm-5 0h3v2H0v-2zm5-8h7v2H5V5zM0 5h3v2H0V5zm5-4h11v2H5V1zM0 1h3v2H0V1z" />
            </svg> */}
            <span className={`text-sm font-medium ${pathname.includes('/account/plans') ? 'text-airco2-secondary' : 'hover:text-gray-700'}`}>{t("account.planes")}</span>
          </NavLink>
          </li>
          </>
          }



          {/* <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink  to="/account/notifications" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/account/notifications') && 'bg-indigo-50'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current text-gray-400 mr-2 ${pathname.includes('/account/notifications') && 'text-indigo-400'}`} viewBox="0 0 16 16">
                <path d="M14.3.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-8 8c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l8-8zM15 7c.6 0 1 .4 1 1 0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8c.6 0 1 .4 1 1s-.4 1-1 1C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6c0-.6.4-1 1-1z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/account/notifications') ? 'text-indigo-500' : 'hover:text-gray-700'}`}>Mis Notificaciones</span>
            </NavLink>
          </li> */}
          {/* <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink  to="/account/apps" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/account/apps') && 'bg-indigo-50'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current text-gray-400 mr-2 ${pathname.includes('/account/apps') && 'text-indigo-400'}`} viewBox="0 0 16 16">
                <path d="M3.414 2L9 7.586V16H7V8.414l-5-5V6H0V1a1 1 0 011-1h5v2H3.414zM15 0a1 1 0 011 1v5h-2V3.414l-3.172 3.172-1.414-1.414L12.586 2H10V0h5z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/account/apps') ? 'text-indigo-500' : 'hover:text-gray-700'}`}>Mis Proyectos CO2</span>
            </NavLink>
          </li> */}
          {/* {UserService.getUserType() === "Representante empresarial" &&
            <li className="mr-0.5 md:mr-0 md:mb-0.5">
              <NavLink to="/company/plans" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/account/plans') && 'bg-indigo-50'}`}>
                <svg className={`w-4 h-4 shrink-0 fill-current text-gray-400 mr-2 ${pathname.includes('company/plans') && 'text-indigo-400'}`} viewBox="0 0 16 16">
                  <path d="M5 9h11v2H5V9zM0 9h3v2H0V9zm5 4h6v2H5v-2zm-5 0h3v2H0v-2zm5-8h7v2H5V5zM0 5h3v2H0V5zm5-4h11v2H5V1zM0 1h3v2H0V1z" />
                </svg>
                <span className={`text-sm font-medium ${pathname.includes('/company/plans') ? 'text-indigo-500' : 'hover:text-gray-700'}`}>{t("account.planes")}</span>
              </NavLink>
            </li>}
          {UserService.getUserType() === "Representante empresarial" && <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink to="/company/billing" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/company/billing') && 'bg-indigo-50'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current text-gray-400 mr-2 ${pathname.includes('/company/billing') && 'text-indigo-400'}`} viewBox="0 0 16 16">
                <path d="M15 4c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3h7c.6 0 1 .4 1 1v3h4zM2 3v1h7V2H3c-.6 0-1 .4-1 1zm12 11V6H2v7c0 .6.4 1 1 1h11zm-3-5h2v2h-2V9z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/company/billing') ? 'text-indigo-500' : 'hover:text-gray-700'}`}>{t("account.facturacion")}</span>
            </NavLink>
          </li>} */}

          {/* <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink  to="/account/feedback" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/account/feedback') && 'bg-indigo-50'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current text-gray-400 mr-2 ${pathname.includes('/account/feedback') && 'text-indigo-400'}`} viewBox="0 0 16 16">
                <path d="M7.001 3h2v4h-2V3zm1 7a1 1 0 110-2 1 1 0 010 2zM15 16a1 1 0 01-.6-.2L10.667 13H1a1 1 0 01-1-1V1a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1zM2 11h9a1 1 0 01.6.2L14 13V2H2v9z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/account/feedback') ? 'text-indigo-500' : 'hover:text-gray-700'}`}>Feedback</span>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </div>
  );
}

export default SettingsSidebar;