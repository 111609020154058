import React, { useContext, useEffect } from 'react'
import UserService from '../../services/UserService';
import logoairco2 from '../../images/byAirco2verde.png'
import { IdiomContext } from "../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { getColor, getLogo, getUrl, getSecondaryColor, getName, getFont } from '../../services/FunctionsDataWhiteLabel';
import { Helmet } from 'react-helmet';

export default function RedirectExternal() {
    const whiteLabel = UserService.getwhiteLabel();
    const token = UserService.getToken()
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const logo = getLogo(whiteLabel)

    const openUrl = () => {
        if (token) {
            window.open('/footprint/welcome', '_blank');
        }
    }

    useEffect(() => {
        openUrl();
    }, [token]);



    return (
        <div className={`min-h-screen h-full flex items-center justify-center ${getFont(whiteLabel)}`}>
            <Helmet>
                <meta name="robots" content=" noindex, nofollow" />
            </Helmet>
            <div className='container'>

                {logo &&
                    <div className='flex justify-center'>
                        <img src={logo} style={{ maxHeight: '150px' }} alt={`${whiteLabel} logo`}></img>
                    </div>

                }


                <div className='flex-col flex-wrap text-xl mt-5  px-4'>
                    <p className='text-center' >{t("auth.redirigir")}</p>
                </div>
                <div className='flex-col flex-wrap text-md mt-5  px-4'>
                    <p className='text-center' >{t("auth.redirigir2")}</p>
                    <p className='text-center' >{t("auth.redirigir3")}</p>
                    <p className='text-center' >{t("auth.redirigir4")}</p>
                </div>

                <div className='flex flex-wrap justify-center gap-5 mt-5'>

                    <a href={`${getUrl(whiteLabel)}`} className={`btn btn-sm whitespace-nowrap`} style={{ backgroundColor: getColor(whiteLabel), color: "white" }}>
                        {t("auth.principal")} {getName(whiteLabel)}
                    </a>

                    <button onClick={() => openUrl()} className={`btn btn-sm whitespace-nowrap`} style={{ backgroundColor: getSecondaryColor(whiteLabel), color: "white" }}>
                        {t("auth.recargarpag")}
                    </button>



                </div>

                <div className='flex justify-center mt-5'>
                    <img className='' src={logoairco2} style={{ height: '25px' }}></img>

                </div>
            </div>

        </div>
    )
}
