import axios from "axios";
import { BarController, ScatterController, BarElement, CategoryScale, Chart, Legend, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from "moment";
import "moment/locale/es";
import "moment/locale/gl";
import "moment/locale/pt";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import UserService from "../../../../services/UserService";
import { formatThousands } from '../../utils/Utils';
import { IdiomContext } from "../../../../context/createcontext";

Chart.register(BarController, ScatterController, BarElement, LinearScale, TimeScale, Tooltip, Legend, CategoryScale);
export default function DecarbonizationChart({ resume, allBuildings }) {
    const [lng, updateLng] = useContext(IdiomContext)
    const [selectedBuilding, setSelectedBuilding] = useState(undefined);

    const chartsTabs = ["Largo plazo", "Medio plazo", "Corto plazo"];
    const [selectedChart, setSelectedChart] = useState(chartsTabs[0]);

    useEffect(() => {
        setSelectedBuilding(allBuildings[0])
    }, [allBuildings])


    const canvas = useRef(null);
    const legend = useRef(null);
    const [t, i18n] = useTranslation("global");
    const currentYear = new Date().getFullYear();

    const scope1and2Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scopes1and2');
    const scope1and2LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scopes1and2-light');
    const evolutionScope1and2Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scopes1and2-evolution');
    const evolutionScope1and2LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scopes1and2-evolution-light');

    const scope3Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3');
    const scope3LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3-light');
    const evolutionscope3Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3-evolution');
    const evolutionScope3LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3-evolution-light');

    const colorRealScope12 = '#e67740';
    const colorRealScope3 = '#4d88c6';

    const scope1and2ColorSecondary1Bar = "#ffb366";
    const scope3ColorSecondary1Bar = "#3d74b4";

    const scope1and2ColorSecondary2Bar = "#ffcc99";
    const scope3ColorSecondary2Bar = "#80b3e6";

    useEffect(() => {
        if (!resume) return;
        if (!selectedBuilding) return;

        const scenarioYears = Array.from({ length: resume.targetYear - resume.baseYear + 1 }, (_, i) => resume.baseYear + i);
        const indexCurrentYear = scenarioYears.findIndex((element) => element == currentYear);

        const yearlyScopes1_2ProgressionByAppliedReduction = calcularValoresAnuales(resume.tco2eScopes1and2, resume.currentScopes1and2, resume.baseYear, resume.targetYear);
        const yearlyScope3ProgressionByAppliedReduction = calcularValoresAnuales(resume.tco2eScope3, resume.currentScope3, resume.baseYear, resume.targetYear);

        const yearlyTargetTrendScopes1_2 = calcularValoresAnuales(resume.tco2eScopes1and2, resume.targetScopes1and2, resume.baseYear, resume.targetYear);
        const yearlyTargetTrendScope3 = calcularValoresAnuales(resume.tco2eScope3, resume.targetScope3, resume.baseYear, resume.targetYear);


        const uniqueYearTotalScopesList = resume.calculationTotalScopesList.reduce((acc, curr) => {
            // Busca si ya existe un objeto con el mismo año en el acumulador
            const existing = acc.find(item => item.year === curr.year);

            if (existing) {
                // Si existe, suma las propiedades
                existing.scope1 += curr.scope1;
                existing.scope2 += curr.scope2;
                existing.scope3 += curr.scope3;
            } else {
                // Si no existe, añade un nuevo objeto al acumulador
                acc.push({ ...curr });
            }

            return acc;
        }, []);

        let years;

        let yearlyDataScopes1and2 = [];
        let yearlyDataScope3 = [];

        let yearlyTrendDataScopes1and2 = [];
        let yearlyTrendDataScope3 = [];

        let legendMonthlyVierw = []

        let yearlyRealDataScopes1and2 = [];
        let yearlyRealdDataScope3 = [];

        switch (selectedChart) {
            case "Largo plazo":

                years = scenarioYears;

                yearlyDataScopes1and2 = yearlyScopes1_2ProgressionByAppliedReduction;
                yearlyDataScope3 = yearlyScope3ProgressionByAppliedReduction;

                yearlyTrendDataScopes1and2 = yearlyTargetTrendScopes1_2;
                yearlyTrendDataScope3 = yearlyTargetTrendScope3;

                if (uniqueYearTotalScopesList.length > 0) {
                    yearlyRealDataScopes1and2 = new Array(years.length);
                    yearlyRealdDataScope3 = new Array(years.length);

                    uniqueYearTotalScopesList.forEach((item, i) => {
                        let indexYear = years.findIndex((element) => element == item.year);
                        if (indexYear != -1) {
                            if (selectedBuilding === t("decarbonization.allOrganizationBuildings")) { 
                                let sumsBuildingsLongScopes1and2 = 0;
                                let sumsBuildingsLongScope3 = 0;
                                item.calculationTotalScopesByBuildingList.forEach(building => {
                                    sumsBuildingsLongScopes1and2 += (building.scope1.reduce((accumulator, currentValue) => accumulator + currentValue, 0) + building.scope2.reduce((accumulator, currentValue) => accumulator + currentValue, 0));
                                    sumsBuildingsLongScope3 += building.scope3.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                                });

                                yearlyRealDataScopes1and2[indexYear] = sumsBuildingsLongScopes1and2;
                                yearlyRealdDataScope3[indexYear] = sumsBuildingsLongScope3;

                            } else {
                                const buildingData = item.calculationTotalScopesByBuildingList.find(i => i.organizationBuilding === selectedBuilding);
                                if (buildingData) {
                                    yearlyRealDataScopes1and2[indexYear] = buildingData.scope1.reduce((accumulator, currentValue) => accumulator + currentValue, 0) + buildingData.scope2.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                                    yearlyRealdDataScope3[indexYear] = buildingData.scope3.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                                }
                            }
                        }
                    })
                }
                break;
            case "Medio plazo":
                years = [];
                years.push(scenarioYears[0]);
                scenarioYears.slice(indexCurrentYear, indexCurrentYear + 3).forEach((year) => years.push(year))

                yearlyDataScopes1and2.push(yearlyScopes1_2ProgressionByAppliedReduction[0]);
                yearlyDataScope3.push(yearlyScope3ProgressionByAppliedReduction[0]);
                yearlyTrendDataScopes1and2.push(yearlyTargetTrendScopes1_2[0]);
                yearlyTrendDataScope3.push(yearlyTargetTrendScope3[0]);

                yearlyScopes1_2ProgressionByAppliedReduction.slice(indexCurrentYear, indexCurrentYear + 3).forEach((x) => yearlyDataScopes1and2.push(x));
                yearlyScope3ProgressionByAppliedReduction.slice(indexCurrentYear, indexCurrentYear + 3).forEach((x) => yearlyDataScope3.push(x));
                yearlyTargetTrendScopes1_2.slice(indexCurrentYear, indexCurrentYear + 3).map((x) => yearlyTrendDataScopes1and2.push(x));
                yearlyTargetTrendScope3.slice(indexCurrentYear, indexCurrentYear + 3).map((x) => yearlyTrendDataScope3.push(x));

                if (uniqueYearTotalScopesList.length > 0) {
                    yearlyRealDataScopes1and2 = new Array(years.length);
                    yearlyRealdDataScope3 = new Array(years.length);

                    uniqueYearTotalScopesList.forEach((item, i) => {
                        let indexYear = years.findIndex((element) => element == item.year);
                        if (indexYear != -1) {
                            if (selectedBuilding === t("decarbonization.allOrganizationBuildings")) {
                                let sumsBuildingsLongScopes1and2 = 0;
                                let sumsBuildingsLongScope3 = 0;
                                item.calculationTotalScopesByBuildingList.forEach(building => {
                                    sumsBuildingsLongScopes1and2 += (building.scope1.reduce((accumulator, currentValue) => accumulator + currentValue, 0) + building.scope2.reduce((accumulator, currentValue) => accumulator + currentValue, 0));
                                    sumsBuildingsLongScope3 += building.scope3.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                                });

                                yearlyRealDataScopes1and2[indexYear] = sumsBuildingsLongScopes1and2;
                                yearlyRealdDataScope3[indexYear] = sumsBuildingsLongScope3;
                            } else {
                                const buildingData = item.calculationTotalScopesByBuildingList.find(i => i.organizationBuilding === selectedBuilding);
                                if (buildingData) {
                                    yearlyRealDataScopes1and2[indexYear] = buildingData.scope1.reduce((accumulator, currentValue) => accumulator + currentValue, 0) + buildingData.scope2.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                                    yearlyRealdDataScope3[indexYear] = buildingData.scope3.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                                }
                            }
                        }
                    })
                }
                break;
            case "Corto plazo":
                years = [
                    "Enero", "Febrero", "Marzo", "Abril",
                    "Mayo", "Junio", "Julio", "Agosto",
                    "Septiembre", "Octubre", "Noviembre", "Diciembre"
                ];

                legendMonthlyVierw = [resume.baseYear, currentYear - 1, currentYear];

                if (currentYear - 1 == resume.baseYear) {
                    legendMonthlyVierw = [resume.baseYear, currentYear];
                }

                if (uniqueYearTotalScopesList.length > 0) {
                    yearlyDataScopes1and2 = new Array(legendMonthlyVierw.length);
                    yearlyDataScope3 = new Array(legendMonthlyVierw.length);

                    legendMonthlyVierw.forEach((year, indexYear) => {
                        let yearData = uniqueYearTotalScopesList.find(element => element.year === year);
                        if (yearData) {
                            if (selectedBuilding === t("decarbonization.allOrganizationBuildings")) {
                                let sumsBuildingsLongScopes1and2 = new Array(12).fill(0);
                                let sumsBuildingsLongScope3 = new Array(12).fill(0);
                                yearData.calculationTotalScopesByBuildingList.forEach(building => {
                                    sumsBuildingsLongScopes1and2 = sumsBuildingsLongScopes1and2.map((value, index) =>
                                        value + (building.scope1[index] || 0) + (building.scope2[index] || 0)
                                    );
                                    sumsBuildingsLongScope3 = sumsBuildingsLongScope3.map((value, index) =>
                                        value + (building.scope3[index] || 0)
                                    );
                                });
                                yearlyDataScopes1and2[indexYear] = sumsBuildingsLongScopes1and2;
                                yearlyDataScope3[indexYear] = sumsBuildingsLongScope3;

                            } else {
                                const buildingData = yearData.calculationTotalScopesByBuildingList.find(i => i.organizationBuilding === selectedBuilding);
                                if (buildingData) {
                                    yearlyDataScopes1and2[indexYear] = buildingData.scope1.map((value, index) => value + (buildingData.scope2[index] || 0));
                                    yearlyDataScope3[indexYear] = buildingData.scope3;
                                }
                            }
                        } else {
                            yearlyDataScopes1and2[indexYear] = new Array(12).fill(null);
                            yearlyDataScope3[indexYear] = new Array(12).fill(null);
                        }
                    });
                }
                break;
        }

        let data = {
            labels: years,
            datasets: [
                {
                    label: t("decarbonization.scopes1and2"),
                    data: yearlyDataScopes1and2,
                    backgroundColor: scope1and2Color,
                    hoverBackgroundColor: scope1and2LightColor,
                    stack: 'a',
                    borderWidth: 1,
                    order: 3,
                    fill: false,
                },
                {
                    label: t("decarbonization.scope3"),
                    data: yearlyDataScope3,
                    backgroundColor: scope3Color,
                    hoverBackgroundColor: scope3LightColor,
                    stack: 'b',
                    borderWidth: 1,
                    order: 3,
                    fill: false,
                },
                {
                    label: t("decarbonization.targetScopes1and2"),
                    data: yearlyTrendDataScopes1and2,
                    type: 'line',
                    borderColor: evolutionScope1and2Color,
                    backgroundColor: evolutionScope1and2LightColor,
                    yAxisID: 'y',
                    fill: false,
                    order: 2,
                    pointRadius: 1,
                },
                {
                    label: t("decarbonization.targetscope3"),
                    data: yearlyTrendDataScope3,
                    type: 'line',
                    borderColor: evolutionscope3Color,
                    backgroundColor: evolutionScope3LightColor,
                    yAxisID: 'y',
                    fill: false,
                    order: 2,
                    pointRadius: 1,
                },
                {
                    label: "Real " + t("decarbonization.scopes1and2"),
                    data: yearlyRealDataScopes1and2,
                    type: 'line',
                    borderColor: colorRealScope12,
                    backgroundColor: colorRealScope12,
                    yAxisID: 'y',
                    borderWidth: 1,
                    order: 2,
                    tension: 0.2,
                    pointRadius: 1,
                },
                {
                    label: "Real " + t("decarbonization.scope3"),
                    data: yearlyRealdDataScope3,
                    type: 'line',
                    borderColor: colorRealScope3,
                    backgroundColor: colorRealScope3,
                    yAxisID: 'y',
                    borderWidth: 1,
                    order: 2,
                    tension: 0.2,
                    pointRadius: 1
                }
            ],
        };

        if (selectedChart === "Corto plazo") {
            data = {
                labels: years,
                datasets: [
                    {
                        label: t("decarbonization.scopes1and2") + " " + legendMonthlyVierw[0],
                        data: yearlyDataScopes1and2[0],
                        backgroundColor: scope1and2Color,
                        hoverBackgroundColor: scope1and2LightColor,
                        borderWidth: 1,
                        stack: 'a',
                        order: 1,
                        fill: false,
                    },
                    {
                        label: t("decarbonization.scope3") + " " + legendMonthlyVierw[0],
                        data: yearlyDataScope3[0],
                        backgroundColor: scope3Color,
                        hoverBackgroundColor: scope3LightColor,
                        borderWidth: 1,
                        stack: 'a',
                        order: 2,
                        fill: false,
                    },
                    {
                        label: t("decarbonization.scopes1and2") + " " + legendMonthlyVierw[1],
                        data: yearlyDataScopes1and2[1],
                        backgroundColor: scope1and2ColorSecondary1Bar,
                        hoverBackgroundColor: scope1and2ColorSecondary1Bar,
                        borderWidth: 1,
                        stack: 'b',
                        order: 3,
                        fill: false,
                    },
                    {
                        label: t("decarbonization.scope3") + " " + legendMonthlyVierw[1],
                        data: yearlyDataScope3[1],
                        backgroundColor: scope3ColorSecondary1Bar,
                        hoverBackgroundColor: scope3ColorSecondary1Bar,
                        stack: 'b',
                        borderWidth: 1,
                        order: 4,
                        fill: false,
                    }, {
                        label: t("decarbonization.scopes1and2") + " " + legendMonthlyVierw[2],
                        data: yearlyDataScopes1and2[2],
                        backgroundColor: scope1and2ColorSecondary2Bar,
                        hoverBackgroundColor: scope1and2ColorSecondary2Bar,
                        borderWidth: 1,
                        stack: 'c',
                        order: 5,
                        fill: false,
                    },
                    {
                        label: t("decarbonization.scope3") + " " + legendMonthlyVierw[2],
                        data: yearlyDataScope3[2],
                        backgroundColor: scope3ColorSecondary2Bar,
                        hoverBackgroundColor: scope3ColorSecondary2Bar,
                        borderWidth: 1,
                        order: 6,
                        stack: 'c',
                        fill: false,
                    },
                ],
            };
        }

        const ctx = canvas.current;
        // eslint-disable-next-line no-unused-vars
        switch (lng) {
            case "en":
                moment.locale("en");
                break;
            case "pt":
                moment.locale("pt");
                break;
            case "gl":
                moment.locale("gl");
                break;
            default:
                moment.locale("es");
                break;
        }
        const chart = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Chart.js Bar Chart - Stacked'
                    },

                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const dataset = context.dataset;
                                const valorFormateado = `${formatThousands(context.parsed.y)} kgCO₂e`;
                                return `${dataset.label}: ${valorFormateado}`;
                            },
                        },
                    },

                    legend: {
                        display: true,
                    },
                },
                // maintainAspectRatio: false,
                responsive: true,
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                        type: 'category',
                    },
                    y: {
                        stacked: false,
                        beginAtZero: true
                    }
                }
            }

        });

        return () => chart.destroy();
        // eslint-disable-next-line
    }, [lng, resume, selectedChart, selectedBuilding]);

    function calcularValoresAnuales(valorBase, reduccionTotal, anioInicial, anioFinal) {
        const numAnios = anioFinal - anioInicial;
        const tasaReduccionAnual = 1 - Math.pow((1 - (reduccionTotal / 100)), 1 / numAnios);

        const valoresAnuales = [valorBase]; // Iniciar con el valor base

        for (let i = 1; i <= numAnios; i++) {
            const valorAnterior = valoresAnuales[i - 1];
            const valorActual = valorAnterior * (1 - tasaReduccionAnual);
            valoresAnuales.push(valorActual);
        }

        return valoresAnuales;
    }

    return (
        <React.Fragment>
            <>
                <h3 className="text-center font-bold mb-2">{t("evolution.infoAlcance")}</h3>
                {allBuildings.length > 1 && <div className="mb-4">
                    <ul className="flex flex-wrap items-center justify-center -m-1">
                        {allBuildings.map((building, index) => <li className="m-1" key={`chart-building-${building}`}>
                            <button onClick={() => setSelectedBuilding(building)} className={`${selectedBuilding === building ? 'sec-green-background text-white' : ' text-gray-500'} 
                                inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank shadow-sm duration-150 ease-in-out`}>
                                {building}
                            </button>
                        </li>)}
                    </ul>
                </div>}
                <div className="mb-3">
                    <ul className="flex justify-center flex-wrap -m-1">
                        {chartsTabs.map((tab) => {
                            return (
                                <li className="m-1" key={tab}>
                                    <button onClick={() => setSelectedChart(tab)} className={`${selectedChart === tab ? 'sec-green-background text-white' : ' text-gray-500'}
                                    inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank shadow-sm duration-150 ease-in-out`}>
                                        {tab}
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                {(!resume) && <div className="text-center"><Spinner className='' /></div>}
                {!canvas !== null && resume && <canvas ref={canvas} style={{ maxHeight: "350px" }}></canvas>}
            </>
        </React.Fragment>
    );


}