import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useCallback } from "react";
import axios from 'axios';
import UserService from '../../../services/UserService';

const FileViewer = ({ getInvoiceProcessorState, invoiceProcessState, inputData, idAddedAnswer, showFileUploaded, questionTitle }) => {
  const [fileSrc, setFileSrc] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [extractedData, setExtractedData] = useState();
  const [dataByInput, setDataByInput] = useState();
  const [file, setFile] = useState();

  const invoiceProcessStates = {
    NOTUPLOADED: 'NOTUPLOADED',
    ERROR: 'ERROR',
    UPLOADING: 'UPLOADING',
    UPLOADED: 'UPLOADED',
    SENT: 'SENT',
    COMPLETED: 'COMPLETED',
    SUCCESS: 'SUCCESS',
    SAVEKO: 'SAVEKO'
  };

  useEffect(() => {
    if (fileSrc && fileType && invoiceProcessState == invoiceProcessStates.UPLOADING) {
      handleProcessFile();
    }
    if (invoiceProcessState == invoiceProcessStates.NOTUPLOADED) {
      setFileSrc(null);
      setFileType(null);
      setDataByInput();
      setExtractedData();
    }
  }, [invoiceProcessState])

  const handleFileChange = (file) => {

    if (file) {
      const fileType = file.type;

      //Extraer la extensión para evitar errores al limpiar el nombre
      let fileNameParts = file.name.split(".");
      let extension = fileNameParts.pop();
      let baseName = fileNameParts.join(".");

      // Reemplaza los caracteres no deseados
      baseName = baseName.replace(/[^a-zA-Z0-9\-_]/g, "_");

      // Nombre limpio con la extensión
      let cleanedFileName = baseName + "." + extension;

      const sanitizedFile = new File([file], cleanedFileName, {
        type: fileType,
      });

      setFileType(fileType);
      setFile(sanitizedFile);

      const fileReader = new FileReader();
      fileReader.onload = () => {
        setFileSrc(fileReader.result);
      };

      // Verificar si es un PDF o una imagen
      if (fileType === 'application/pdf') {
        fileReader.readAsDataURL(sanitizedFile); // Leer PDF como Data URL
        getInvoiceProcessorState({ state: invoiceProcessStates.UPLOADED, extractedData: undefined })
      } else if (fileType.startsWith('image/')) {
        fileReader.readAsDataURL(sanitizedFile); // Leer imagen como Data URL
        getInvoiceProcessorState({ state: invoiceProcessStates.UPLOADED, extractedData: undefined })
      } else {
        getInvoiceProcessorState({ state: invoiceProcessStates.NOTUPLOADED, extractedData: undefined })
        alert('Por favor, sube un archivo PDF o una imagen.');
        setFileSrc(null);
        setFileType(null);
      }
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    handleFileChange(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  });


  //TODO: REPASAR LLAMDA A GUARDAR DOPCUMENTO QUE SE HACE RANDOM
  useEffect(() => {
    if (idAddedAnswer != undefined) {
      handleUploadFileAnswer(idAddedAnswer.data.id, idAddedAnswer.data.calculationId)
    }
  }, [idAddedAnswer])

  const handleProcessFile = async () => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('text', JSON.stringify(inputData));

    if (questionTitle == "Consumo de electricidad") {
      formData.append('electricity', true);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_CH_INVOICE_EXTRACTION}`,
        formData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${UserService.getToken()}`
        }
      });
      let data = response.data.data;
      setExtractedData(response.data)
      if (response.data.success) {
        getInvoiceProcessorState({ state: invoiceProcessStates.COMPLETED, extractedData: JSON.parse(data.substring(7, data.length - 3)) })
        setDataByInput(JSON.parse(data.substring(7, data.length - 3)))
      } else {
        getInvoiceProcessorState({ state: invoiceProcessStates.ERROR, extractedData: undefined })
      }
      // setFile(null);
    } catch (exception) {
      console.error(exception);
      getInvoiceProcessorState({ state: invoiceProcessStates.ERROR, extractedData: undefined })
    }

  }

  const handleUploadFileAnswer = async (idCalculationAnswer, idCalculation) => {
    // setUploadSpiner(true);
    let formData = new FormData();
    formData.append('files', file);
    try {
      const response = await axios.post(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/documentation?idQuestionAnswer=${idCalculationAnswer}`,
        formData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${UserService.getToken()}`
        }
      });
      if (response.data.success) {
        showFileUploaded(response.data.data)
      }

      setFile(null);

    } catch (exception) {
      console.error(exception);
    }
    // setUploadSpiner(false);

  }


  return (
    <div>
      {fileSrc == null &&
        <div {...getRootProps()} style={{ border: isDragActive ? '3px dashed #f19b7f' : '3px dashed #878787', padding: '20px', textAlign: 'center', height: "600px", borderRadius: "10px", alignContent: "center" }} className="mb-4">
          <input {...getInputProps({ accept: '.pdf, image/*' })} />
          {
            isDragActive ?
              <p>Suelta el archivo</p> :
              <div className="alig-center">
                <div className=" flex justify-center items-center gap-4 mb-2 ">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-photo" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#878787" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M15 8h.01" />
                    <path d="M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z" />
                    <path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5" />
                    <path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l3 3" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-type-pdf" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#878787" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
                    <path d="M5 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" />
                    <path d="M17 18h2" />
                    <path d="M20 15h-3v6" />
                    <path d="M11 15v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z" />
                  </svg>
                </div>
                <p style={{ color: '#878787' }} className="font-semibold">Arrastra o haz click aquí para subir el archivo</p>
              </div>
          }
        </div>}

      {fileSrc && fileType === 'application/pdf' && (
        <div style={{ width: '100%', height: '600px' }}>
          <iframe
            src={fileSrc}
            title="PDF Viewer"
            style={{ width: '100%', height: '100%' }}
            type={fileType}
          />
        </div>
      )}

      {fileSrc && fileType.startsWith('image/') && (
        <div className="flex justify-center">
          <img src={fileSrc} alt="Uploaded file" style={{ maxWidth: '100%', height: 'auto' }} />
        </div>
      )}
    </div>
  );
};

export default FileViewer;