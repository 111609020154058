import React, { useState, useContext, useEffect } from 'react';

import Sidebar from './../Sidebar';
import Header from './../Header';
import MapaMultiple from './mapa_catastro_multiple';
import Form from "react-bootstrap/Form";
import FormMontesMapMultiple from './FormMontesMap_multiple';
import UserService from './../../../services/UserService';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import provincias from "./../data/provincias.json";
import { useLocation } from 'react-router-dom';
function NewForestFormMultiple() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const [formMonte, setFormMonte] = useState(false);
  const location = useLocation();

  const propsTotal = location.state;

  const propsPadre = location?.state?.montePadre
  const propsChild = location?.state?.childList
  const propsRecibidas = location?.state?.childList
  let propsPadreChild = null
  if (propsRecibidas != null) {
    propsPadreChild = [
      propsPadre,
      ...propsChild
    ];

  }



  const [infoParcelas, setInfoParcelas] = useState([]);
  const [parcela2delete, setParcela2delete] = useState();
  const [spinner, setSpinner] = useState(false);
  const [booleanMap, setBooleanMap] = useState(true);

  const [refCatParcelData, setRefCatParcelData] = useState();
  const [refCatParcelDataList, setRefCatParcelDataList] = useState();



  const sendDataToParent = (props) => {
    setInfoParcelas(props);
   
  };

  //Función que recoge el objeto a borrar desde el formulario hijo, lo elimina del array y guarda este array en el estado que se pasa al mapa.
  const sendParcel2Delete = (props) => {
    const modifiedArray = infoParcelas.filter(parcel => parcel.cadastralReference != props.cadastralReference);
    setParcela2delete(modifiedArray);
  };

  // const onDeleteEdit=(cadastralReference)=>{
  //   setRefCatParcelDataList(prevInfoParcelasSelec =>
  //     prevInfoParcelasSelec.filter(parcel => parcel.cadastralReference !== cadastralReference)
  //   )
  // }

  

  const [catastroCaido, setCatastroCaido] = useState(false);
  const [pingCatastro, setPingCatastro] = useState();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_CATASTRO_BY_REFCAT}27027A02601426`, {
      method: "GET",
      headers: {
        "Accept": "*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${UserService.getToken()}`
      },
    })
      .then((response) => {
        if (response.status == "500") {
          setCatastroCaido(true)
        }
        return response.json();
      })
      .then((pingCatastro) => {
        if (!pingCatastro.error) {
          setPingCatastro(pingCatastro);
        }
      });
  }, [])

  function getInfoByCatastral() {
    let refCat = document.getElementById("catastralsearch").value

    fetch(`${process.env.REACT_APP_CATASTRO_BY_REFCAT}` + refCat, {
      method: "GET",
      headers: {
        "Accept": "*",
        "Content-Type": "application/json",
        // "Allow": "*",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${UserService.getToken()}`
      },
    })
      .then((response) => {
        if (response.status != "200") {
          setBooleanMap(true)
        }
        if (response.status == "400") {
          setModal(true);
        }
        else if (response.status == "500") {
          setModal(true);
        }
        return response.json();
      })
      .then((refCatParcelData) => {
        if (!refCatParcelData.error) {
          setRefCatParcelData(refCatParcelData);
        }
      });
  }

  function getInfoByCatastralesEdit(propsRecibidas) {
    const promises = propsRecibidas.map((x) => {
      const refCat = x.cadastre;

      return fetch(`${process.env.REACT_APP_CATASTRO_BY_REFCAT}` + refCat, {
        method: "GET",
        headers: {
          "Accept": "*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${UserService.getToken()}`
        },
      })
        .then((response) => response.json())
        .then((data) => {

          const coordinatesArray = data.polygonCoordinates.split(' ').map(Number);

          const pairCount = coordinatesArray.length / 2;
          const pairs = [];

          for (let i = 0; i < pairCount; i++) {
            const latIndex = i * 2;
            const lonIndex = latIndex + 1;

            const lat = coordinatesArray[latIndex];
            const lon = coordinatesArray[lonIndex];

            pairs.push([lat, lon]);
          }

          data.polygonCoordinates = pairs;
          return data;
        })
        .catch((error) => {
          console.error("Error al realizar las solicitudes:", error);
        });
    });

    Promise.all(promises)
      .then((responses) => {
        setRefCatParcelDataList(responses);
      });
  }

  useEffect(() => {
    if (propsRecibidas) {
      getInfoByCatastralesEdit(propsPadreChild);
    }
  }, [propsRecibidas]);


  const handlerSubmit = e => {
    e.preventDefault();
    setSpinner(true);

    const monte = {
      "owner": e.target.titular.value,
      "size": "",
      "cadastre": "",
      "forestType": "",
      "age": "",
      "marco": "",
      "name": e.target.nombre.value.trim(),
      "creationDate": new Date(),
      "userId": UserService.getUserId(),
      "province": "",
      "state": "CREATED"
    }

    //   // useEffect(() => {
    const fetchForestUploaded = async () => {
      try {
        setForestUploaded({ forestUploadedData: forestUploaded.forestUploadedData, isFetching: true });
        await axios.post(`${process.env.REACT_APP_FOREST}?email=${UserService.getUsername()}`, monte, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
          // body:JSON.stringify(monte)
        }).then(response => {
          setFormMonte(true);
          setForestUploaded({ forestUploadedData: response.data, isFetching: false });
          setSpinner(true);
          setBooleanMap(false);
          // setLoading(false)
        });
      } catch (exception) {
        console.error(exception);
        // setLoading(false)
        setForestUploaded({ forestUploadedData: forestUploaded.forestUploadedData, isFetching: false });
        setSpinner(true);
      }
    };
    fetchForestUploaded();
  }

  //Estado con todas las especies de BBDD
  const [forestType, setForestType] = useState({
    forestTypeData: [],
    isFetching: false,
  });

  useEffect(() => {
    const fetchForestType = async () => {
      try {
        setForestType({ forestTypeData: forestType.forestTypeData, isFetching: true });
        await axios.get(`${process.env.REACT_APP_FORESTTYPE}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setForestType({ forestTypeData: response.data, isFetching: false });
          // setItems(response.data);
        });
      } catch (exception) {
        console.error(exception);
        setForestType({ forestTypeData: forestType.forestTypeData, isFetching: false });
      }
    };
    fetchForestType();
  }, []);

  //Implementar todos los años con compensaciones y settear al actual
  const [forestUploaded, setForestUploaded] = useState({
    forestUploadedData: [],
    isFetching: false,
  });

  const [modal, setModal] = useState(false);
  const onDismiss = () => setModal(false);

  
 
 
 
  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} className="z-40" />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            <Alert color="primary" isOpen={modal} onClose={() => setModal(false)} dismissible className='mx-4'>
              <h4 className="h4">
                {t("formMonte.alert_titulo")}
              </h4>
              <p>
                {t("formMonte.alert_monteSimple1")}<br />
                <hr className='mt-2 mb-2' />
                <p className="mb-0">
                  {t("formMonte.alert_monteSimple3")}
                  <a className="alert-link" href="https://www.sedecatastro.gob.es/" rel="noreferrer" target="_blank"> {t("formMonte.alert_aqui")}</a>
                </p>
              </p>
              <br />
              <div className='flex justify-center'>
                <Button className="btn btn-green text-white ml-3 whitespace-nowrap " onClick={() => setModal(false)}>{t("formMonte.alert_aceptar")}</Button>
              </div>
            </Alert>
            <Alert color="primary" isOpen={catastroCaido} onClose={() => setCatastroCaido(false)} dismissible className='mx-4'>
              <h4 className="h4">
                {t("formMonte.alert_catastro_titulo")}
              </h4>
              <p>
                {t("formMonte.alert_catastro1")}<br />
                {t("formMonte.alert_catastro2")}
              </p>
              <br />
              <div className='flex justify-center'>
                <Button className="btn btn-green text-white ml-3 whitespace-nowrap "><Link to="/forest/forestlist" style={{ textDecoration: 'none', color: 'inherit' }}>{t("formMonte.alert_aceptar")}</Link></Button>
              </div>
            </Alert>

            {catastroCaido ? <></> :
              <>
                {/* Page header */}
                <div className="mb-8">
                  {/* Title */}
                  <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("formMonte.regismonte")}</h1>
                </div>
                {/* Content */}
                <div className={formMonte || propsRecibidas ? "flex flex-col  md:-mr-px" : "flex flex-col  md:flex-row md:-mr-px"}>
                  <MapaMultiple
                    sendDataToParent={sendDataToParent}
                    parcela2delete={parcela2delete}
                    refCatParcelData={refCatParcelData}
                    refCatParcelDataEdit={refCatParcelDataList && refCatParcelDataList.length > 0 ? refCatParcelDataList[0] : null}
                    allParcelsEdit={refCatParcelDataList && refCatParcelDataList.length > 0 ? refCatParcelDataList : null}
                    formMonte={formMonte}
                    mapStep={propsRecibidas ? false : booleanMap} />

                  {formMonte || propsRecibidas ?
                    <FormMontesMapMultiple

                      newForest={propsRecibidas ? propsPadre : forestUploaded.forestUploadedData}
                      dataParcelas={infoParcelas}
                      dataParcelasEdit={refCatParcelDataList}
                      sendParcel2Delete={sendParcel2Delete}
                      infoParcelasEdit={propsPadreChild}
                      // onDeleteEdit={onDeleteEdit} 


                    />
                    :
                    <React.Fragment>
                      <div className="max-w-sm mx-auto px-4" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <div className=''>
                          <div>
                            <ol className='list'>
                              <li className='h5 font-semibold'>{t("formMonte.multi_instructions")}</li>
                              <li><span className='font-semibold'>1. </span>{t("formMonte.multi_instructions1")}</li>
                              <li><span className='font-semibold'>2. </span>{t("formMonte.multi_instructions2")}</li>
                              <li className='mx-4 mt-2'>
                                <label>{t("formMonte.catastral")}</label>
                                <div className='flex'>
                                  <input id="catastralsearch" name="catastralsearch" className="form-input w-full" type="text" minlength="14" maxLength="14" />
                                  <button className=' ml-2 sec-green-background shadow px-1' onClick={() => getInfoByCatastral()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="36" height="36" viewBox="0 0 24 24" strokeWidth="2" stroke="#f8f3ea" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <circle cx="10" cy="10" r="7" />
                                      <line x1="21" y1="21" x2="15" y2="15" />
                                    </svg>
                                  </button>
                                </div>
                              </li>
                              <li className='mt-2'><span className='font-semibold'>3. </span>{t("formMonte.multi_instructions3")}</li>
                              <li><span className='font-semibold'>4. </span>{t("formMonte.multi_instructions4")}</li>
                            </ol>
                          </div>
                        </div>
                        <Form onSubmit={handlerSubmit} className="sec-neutro-background" style={{ width: '100%' }}>
                          <div className="space-y-4">
                            <div className='mb-4'>
                              <label className="block  font-semibold mb-1" htmlFor="nombre">{t("formMonte.nombre")}<span className="text-red-500">* </span></label>
                              <input id="nombre" name="nombre" className="form-input w-full" type="text" required />
                            </div>
                            <div className='mb-4'>
                              <label className="block  font-semibold mb-1" htmlFor="titular">{t("formMonte.titular")}<span className="text-red-500">* </span></label>
                              <input id="titular" name="titular" className="form-input w-full" type="text" required />
                            </div>
                            <div className='flex items-center justify-center pl-4 pr-4'>
                              <input id="checkbox" name="checkbox" className="form-input mb-3" type='checkbox' required />
                              <label className="block text-xs  font-semibold mb-1 ml-1" htmlFor="checkbox">{t("formMonte.multi_checkbox")}<span className="text-red-500">*</span></label>
                            </div>
                            <div className='flex justify-center mt-4'>
                              <button arial-label="btn-regristrar" type="submit" className="btn btn-green text-white ml-3 whitespace-nowrap">
                                {spinner ? <Spinner animation="border" variant="light" /> : t("formMonte.registrar")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </React.Fragment>
                  }
                </div> : </>}
          </div>
        </main>
      </div>
    </div>
  );
}

export default NewForestFormMultiple;