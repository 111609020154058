import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody, OverlayTrigger, Spinner } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import UserService from "../../../../services/UserService";
import { formatThousands } from '../../utils/Utils';

export default function VehicleFleetExcelValidateData({ data, handleResetExcelComponent, inputs, organizationsList, refrigerationType, refrigerationUnitType, vehiclesCombustible, handleFinishUploadOk }) {
    const [t, i18n] = useTranslation("global");

    const [modalDeleteRow, setModalDeleteRow] = useState({ view: false, indexRow: null, spinner: false })
    const [formatData, setFormatData] = useState(data);
    const [showSpinner, setShowSpinner] = useState(false);

    const formatDataRef = useRef(data);

    useEffect(() => {
        formatDataRef.current = formatData;
    }, [formatData])


    const handlerSubmitAnswers = (e) => {
        e.preventDefault();
        setShowSpinner(true);
        // Revisar nulos
        if (formatData.length > 0) {
            for (const row of formatData) {
                if (row.some(obj => !obj.valid)) {
                    toast(t("footPrint.vehicleFleet.formExcelDataHasErrors"), {
                        icon: '⚠️',
                    });
                    setShowSpinner(false);
                    return;
                }
            }
        }


        let dataToSave = formatData.map(arr => {
            return arr.reduce((acc, obj) => {
                let value = obj.id !== undefined ? obj.id : obj.name;
                if (obj.label.includes("List")) {
                    value = [value];
                }
                if (value !== undefined && value !== null && value !== "") {
                    acc[obj.label] = value;
                }
                acc.userId = UserService.getUserId();
                return acc;
            }, {});
        });


        const fetchSavedAnswer = async () => {

            toast.promise(
                axios.post(`${process.env.REACT_APP_CH_VEHICLE_FLEET}s`, dataToSave, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                }).then(async (response) => {
                    if (response.data.success) {
                        await handleFinishUploadOk(response.data.data.length);
                        setShowSpinner(false);
                        return response.data;
                    } else {
                        setShowSpinner(false);
                        throw new Error(response.data.message || 'Error inesperado');
                    }
                }),
                {
                    loading: t("footPrint.vehicleFleet.multipleCreating"),
                    success: <b>{t("footPrint.vehicleFleet.multipleCreated").replace("{fleet}", dataToSave.length)}.</b>,
                    error: <b>{t("footPrint.vehicleFleet.error")}</b>,
                    autoClose: false
                }
            );



        }
        fetchSavedAnswer();

    }



    const handleFormDataChange = (newFormatData) => {
        if (JSON.stringify(newFormatData) !== JSON.stringify(formatDataRef.current)) {
            setFormatData(newFormatData);
            formatDataRef.current = newFormatData;
        }
    }


    return <>

        <ModalDelete
            modalDeleteRow={modalDeleteRow}
            setModalDeleteRow={setModalDeleteRow}
            formatDataRef={formatDataRef}
            t={t}
            handleFormDataChange={handleFormDataChange} />

        <div>
            {formatData.length > 0 ?
                <div>
                    <div className=" overflow-x-auto w-full mt-2">
                        <table className="table-auto w-full border border-collapse text-sm">
                            <thead className="text-xs sec-neutro-background">
                                <tr>
                                    {inputs.map((cell, cellIndex) => (
                                        <th key={cellIndex} className="border p-2 text-center">{cell.title} {cell.required && <span className="text-red-500">*</span>}</th>
                                    ))}
                                    <th className='text-center border'>{t("footPrint.borrar")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formatData.map((row, rowIndex) => (
                                    <tr key={rowIndex} className='text-center'
                                        style={{ backgroundColor: row.some(obj => !obj.valid) ? "#f800001f" : "" }}>
                                        {row.map((value, colIndex) => {
                                            const getDropdownValues = (type) => {
                                                if (type === "ORGANIZATIONS") return organizationsList;
                                                if (type === "REFRIGERATIONTYPE") return refrigerationType;
                                                if (type === "REFRIGERATIONUNITTYPE") return refrigerationUnitType;
                                                if (type === "VEHICLES") return vehiclesCombustible;
                                                if (type === "COMBUSTIBLE") return vehiclesCombustible;
                                                return [];
                                            };

                                            const currentType = inputs[colIndex].type;
                                            const formDataKey = inputs[colIndex].label;
                                            const isRequired = inputs[colIndex].required;
                                            const dropdownValues = getDropdownValues(currentType);
                                            return (
                                                <td key={colIndex + "-" + rowIndex} className='border'>
                                                    <CellValidation value={value}
                                                        rowIndex={rowIndex}
                                                        colIndex={colIndex}
                                                        type={currentType}
                                                        formDataKey={formDataKey}
                                                        isRequired={isRequired}
                                                        handleFormDataChange={handleFormDataChange}
                                                        formatDataRef={formatDataRef}
                                                        dropdownSelectsValues={dropdownValues}
                                                        t={t} />
                                                </td>
                                            )
                                        }
                                        )}
                                        <td className='border'>
                                            <div className="flex flex-row justify-center items-center space-x-2">
                                                <button onClick={() => setModalDeleteRow({ view: true, indexRow: rowIndex, spinner: false })} tittle="Eliminar linea">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#f1a187" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M4 7l16 0" />
                                                        <path d="M10 11l0 6" />
                                                        <path d="M14 11l0 6" />
                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className='flex justify-center w-full mb-4'>
                        <button type="button" style={{ width: '250px' }} onClick={handlerSubmitAnswers}
                            className={`btn-sm btn-banner-o mt-4  `} disabled={showSpinner || formatData.length == 0 && true} >
                            {showSpinner ? <Spinner animation="border" variant="light" size="sm" /> : t("footPrint.Guardar")}
                        </button>
                    </div>
                </div>
                :
                <div className="flex flex-col text-sm justify-center text-center">
                    <p>{t("footPrint.modalExcelNoData")}</p>
                    <div><button className="btn-sm btn-green mt-2" type="button" onClick={handleResetExcelComponent}>{t("fpThird.principio")}</button>
                    </div>
                </div>
            }
        </div></>
}


function ModalDelete({ modalDeleteRow, setModalDeleteRow, formatDataRef, t, handleFormDataChange }) {
    const handleDeleteRow = (rowIndex) => {
        setModalDeleteRow({ view: false, indexRow: null, spinner: true })
        let copiaArray = JSON.parse(JSON.stringify(formatDataRef.current));
        copiaArray.splice(rowIndex, 1);
        handleFormDataChange(copiaArray);
        setModalDeleteRow({ view: false, indexRow: null, spinner: false })
    }

    return <Modal show={modalDeleteRow.view} className="xl-secondary-modal" animation={false} data-aos="fade-up" backdropClassName="xl-secondary-modal-backshadow">
        <div className="text-center mt-4 font-semibold h5">{t("footPrint.eliminarFila")}</div>
        <ModalBody>
            <p className="text-center text-sm">{t("footPrint.eliminarFilaSeguro")}</p>
        </ModalBody>
        <div className="flex justify-center space-x-4 mb-4">
            <button className="btn-sm btn-cancel text-xs" onClick={() => setModalDeleteRow({ view: false, indexRow: null, spinner: false })}>{t("footPrint.cancelar")}</button>
            <button className="btn-sm btn-green text-xs" onClick={() => handleDeleteRow(modalDeleteRow.indexRow)}>{modalDeleteRow.spinner ? <Spinner variant="light" animation="border" /> : "Eliminar"}</button>
        </div>
    </Modal>
}


const CellValidation = ({ value, rowIndex, colIndex, type, formDataKey, isRequired, handleFormDataChange, formatDataRef, dropdownSelectsValues, t }) => {
    const [editVisible, setEditVisible] = useState({ row: null, col: null });
    const [changeString, setChangeString] = useState(undefined);
    const formRef = useRef(null);
    const [localTransport, setLocalTransport] = useState(null);
    const [validatedValue, setValidatedValue] = useState(value);
    const [cellStatusIcon, setCellStatusIcon] = useState({
        ok:
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="#8dd4bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M9 12l2 2l4 -4" />
            </svg>,
        ko: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-triangle" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="#ff2825" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 9v4" />
            <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
            <path d="M12 16h.01" />
        </svg>,
        edit: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="#f19b7f" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
            <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
            <path d="M16 5l3 3" />
        </svg>
    });


    const validateCell = (value, type, isRequired) => {
        let isValid = false;
        let targetId = value.id;
        switch (type) {
            case "STRING":
                isValid = isRequired ? (value.name ? true : false) : true;
                break;
            case "EMAIL":
                isValid = isRequired
                    ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.name)
                    : value.name ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.name) : true;
                break;
            case "NUMBER":
                isValid = isRequired
                    ? (typeof value.name === "number" || (typeof value.name === "string" && value.name.trim() !== "" && !isNaN(value.name)))
                    : true;
                break;
            case "BOOLEAN":
                isValid =
                    typeof value.name === 'boolean' ||
                        (typeof value.name === 'string' &&
                            (value.name.toUpperCase() === 'TRUE' ||
                                value.name.toUpperCase() === 'FALSE')
                        )
                        ? true
                        : false;
                if (isValid) {
                    if (typeof value.name === 'string' && value.name.toUpperCase() === 'TRUE') {
                        value.name = true;
                    }
                    if (typeof value.name === 'string' && value.name.toUpperCase() === 'FALSE') {
                        value.name = false;
                    }
                }
                break;
            case "COMBUSTIBLE":
                if (!isRequired && !value.name) {
                    isValid = true;
                } else {
                    let selectedVehicle = formatDataRef.current[rowIndex].find(f => f.label === "vehiclesMachineryId").id;
                    if (selectedVehicle) {
                        for (const transportsContainer of dropdownSelectsValues) {
                            for (const vehicleContainer of transportsContainer.listGroupEmissions) {
                                const combustibleItem = vehicleContainer.combustible.find(combustibleX => value.name && (combustibleX.name.includes(value.name) || value.name.includes(combustibleX.name)));
                                if (combustibleItem) {
                                    isValid = true;
                                    targetId = combustibleItem.id;
                                    break; // Detener la búsqueda si se encuentra un estado válido
                                }
                            }
                        }
                    }
                }
                break;
            case "VEHICLES":
                if (!isRequired && !value.name) {
                    isValid = true;
                } else {
                    let vehicleName = "";
                    for (const transportsContainer of dropdownSelectsValues) {
                        vehicleName = transportsContainer.name;
                        for (const vehicleContainer of transportsContainer.listGroupEmissions) {

                            if (vehicleContainer.name.includes(value.name) || value.name.includes(vehicleContainer.name)) {
                                isValid = true;
                                targetId = vehicleContainer.mainQuestion;
                                setLocalTransport(vehicleName);
                                break; // Detener la búsqueda si se encuentra un estado válido
                            }
                        }
                    }
                }
                break;
            case "ORGANIZATIONS":
            case "REFRIGERATIONTYPE":
            case "REFRIGERATIONUNITTYPE":
                if (!isRequired && !value.name) {
                    isValid = true;
                } else {
                    isValid = dropdownSelectsValues.some(selectValue => {
                        if (selectValue.name === value.name && value.name !== "") {
                            targetId = selectValue.id;
                            return true;
                        }
                        return false;
                    });
                }
                break;

            default:
                isValid = true; // Por defecto, todo es válido
        }

        const newValidatedValue = { ...value, valid: isValid, id: targetId };
        const currentFormatData = formatDataRef.current;
        const currentRow = currentFormatData[rowIndex];
        const currentCell = currentRow[colIndex];

        if (currentCell.valid !== isValid || currentCell.id !== targetId) {
            const newFormatData = [...currentFormatData];
            newFormatData[rowIndex] = [...currentRow];
            newFormatData[rowIndex][colIndex] = newValidatedValue;
            handleFormDataChange(newFormatData);
        }
        return newValidatedValue;
    };

    useEffect(() => {
        // Validate the initial value when the component mounts
        const initialValidation = validateCell(value, type, isRequired);
        setValidatedValue(initialValidation);
        // Simulamos un cambio en el checkbox si es un BOOLEAN
        if (type === "BOOLEAN") {
            const valueBoolean = typeof value.name === 'string' && value.name.toUpperCase() === 'TRUE' ? true : false;
            handleChangeInputValue(valueBoolean, valueBoolean);
        }
    }, [value, type, isRequired]);


    const renderReadMode = () => (
        <div className="flex justify-center gap-1 items-center">
            <span>
                {validatedValue.name === true
                    ? "Sí"
                    : validatedValue.name === false
                        ? "No"
                        : type === "NUMBER" && (!isNaN(Number(validatedValue.name)) && validatedValue.name !== null && validatedValue.name !== "")
                            ? formatThousands(validatedValue.name)
                            : validatedValue.name}
            </span>
            <span>{validatedValue.valid ? cellStatusIcon.ok : cellStatusIcon.ko}</span>
            <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellStatusIcon.edit}</span>
        </div>
    );

    const renderTooltipSaveOne = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.guardarCambio")}
        </Tooltip>
    );


    const renderTooltipSaveAll = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.aplicarTodos")}
        </Tooltip>
    );

    const renderTooltipSaveExit = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.volver")}
        </Tooltip>
    );


    const renderEditMode = () => (
        <div className="text-sm flex flex-col items-center justify-center mb-2">
            <form
                className="flex flex-col items-center"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleApplyAll(value.name, formDataKey, colIndex);
                    setEditVisible({ row: null, col: null });
                }}
                ref={formRef}
            >
                {(type === "STRING" || type === "EMAIL" || type === "NUMBER") &&
                    <input
                        type={type === "EMAIL" ? "email" : type === "NUMBER" ? "number" : "text"}
                        id={formDataKey}
                        defaultValue={value.name}
                        onChange={(e) => handleChangeInputValue(e.target.value, e.target.value)}
                        min={type === "NUMBER" ? 0.01 : undefined}
                        step={type === "NUMBER" ? 0.01 : undefined}
                        required={isRequired}
                        className='text-xs my-1 w-full'
                    />}

                {type === "BOOLEAN" && (
                    <input
                        type="checkbox"
                        id={formDataKey}
                        defaultChecked={value.name === true}
                        onChange={(e) => handleChangeInputValue(e.target.checked, e.target.checked)}
                        className='text-xs my-1'
                    />
                )}

                {(type === "ORGANIZATIONS" || type === "REFRIGERATIONUNITTYPE" || type === "REFRIGERATIONTYPE") && <select
                    id={type}
                    className='text-xs my-1 w-full'
                    style={{ maxWidth: "220px" }}
                    onChange={(e) => handleChangeInputValue(JSON.parse(e.target.value).name, JSON.parse(e.target.value).id)}
                    defaultValue={
                        dropdownSelectsValues.find(opt => opt.name === value.name)
                            ? JSON.stringify({ name: value.name, id: dropdownSelectsValues.find(opt => opt.name === value.name).id })
                            : ""
                    }
                    required={isRequired}
                >
                    <option value="">{t("footPrint.selecciona")}</option>
                    {dropdownSelectsValues.map((opt) => (
                        <option key={opt.id} value={JSON.stringify({ name: opt.name, id: opt.id })}>
                            {opt.name}
                        </option>
                    ))}
                </select>}

                {type === "VEHICLES" && <>
                    <select
                        id="transportId"
                        className='text-xs my-1 w-full'
                        onChange={(e) => {
                            setLocalTransport(JSON.parse(e.target.value).id);
                        }}
                        defaultValue={
                            dropdownSelectsValues.find(opt => opt.name === localTransport)
                                ? JSON.stringify({ name: localTransport, id: dropdownSelectsValues.find(opt => opt.name === localTransport).name })
                                : ""
                        }
                        required={isRequired}
                    >
                        <option value="">{t("footPrint.selecciona")}</option>
                        {dropdownSelectsValues.map((opt) => (
                            <option key={opt.name} value={JSON.stringify({ name: opt.name, id: opt.name })}>
                                {opt.name}
                            </option>
                        ))}
                    </select>
                    <select
                        id="vehiclesMachineryId"
                        className='text-xs my-1 w-full'
                        onChange={(e) => {
                            handleChangeInputValue(JSON.parse(e.target.value).name, JSON.parse(e.target.value).id);
                        }}
                        defaultValue={
                            (() => {
                                const matchedVehicle = dropdownSelectsValues
                                    .find((transportsContainer) => transportsContainer.name === localTransport)
                                    ?.listGroupEmissions.find((y) => y.name.includes(value.name) || value.name.includes(y.name));

                                return matchedVehicle
                                    ? JSON.stringify({ name: matchedVehicle.name, id: matchedVehicle.mainQuestion })
                                    : "";
                            })()
                        }
                        required={isRequired}
                    >
                        <option value="">{t("footPrint.selecciona")}</option>

                        {(() => {
                            let availableVehicles = [];
                            for (const transportsContainer of dropdownSelectsValues) {
                                if (localTransport === transportsContainer.name) {
                                    availableVehicles = transportsContainer.listGroupEmissions;
                                    break;
                                }
                            }
                            return availableVehicles.map((opt) => (
                                <option key={opt.id} value={JSON.stringify({ name: opt.name, id: opt.mainQuestion })}>
                                    {opt.name}
                                </option>
                            ))
                        })()}
                    </select>

                </>}

                {type === "COMBUSTIBLE" && <>
                    {formatDataRef.current[rowIndex].find(f => f.label === "vehiclesMachineryId")?.id === undefined && <>{t("footPrint.vehicleFleet.selectVehicleFirst")}</>}
                    {formatDataRef.current[rowIndex].find(f => f.label === "vehiclesMachineryId")?.id !== undefined && <select
                        id={type}
                        className='text-xs my-1 w-full'
                        style={{ maxWidth: "220px" }}
                        onChange={(e) => handleChangeInputValue(JSON.parse(e.target.value).name, JSON.parse(e.target.value).id)}
                        defaultValue={
                            (() => {
                                let selectedVehicle = formatDataRef.current[rowIndex].find(f => f.label === "vehiclesMachineryId")?.id;
                                const matchedCombustible = dropdownSelectsValues
                                    .flatMap((transportsContainer) => transportsContainer.listGroupEmissions).find(y => y.mainQuestion === selectedVehicle)
                                    ?.combustible.find((c) => c.name.includes(value.name) || value.name.includes(c.name));

                                return matchedCombustible
                                    ? JSON.stringify({ name: matchedCombustible.name, id: matchedCombustible.id })
                                    : "";
                            })()
                        }
                        required={isRequired}
                    >
                        <option value="">{t("footPrint.selecciona")}</option>
                        {(() => {
                            let selectedVehicle = formatDataRef.current[rowIndex].find(f => f.label === "vehiclesMachineryId")?.id;

                            if (!selectedVehicle) return null
                            let availableCombustibles = [];
                            for (const transportsContainer of dropdownSelectsValues) {
                                for (const vehicleContainer of transportsContainer.listGroupEmissions) {
                                    if (vehicleContainer.mainQuestion === selectedVehicle) {
                                        availableCombustibles = vehicleContainer.combustible;
                                        break;
                                    }
                                }
                            }
                            return availableCombustibles.map((opt) => (
                                <option key={opt.id} value={JSON.stringify({ name: opt.name, id: opt.id })}>
                                    {opt.name}
                                </option>
                            ))
                        })()}
                    </select>}
                </>}

                <div className="flex justify-between w-full gap-1">
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipSaveOne}
                    >
                        <button
                            type="button"
                            className="text-xs btn-sm btn-green"
                            style={{ padding: '4px', borderRadius: '0.125rem' }}
                            onClick={() => {
                                handleApplyOne(rowIndex, colIndex, changeString?.id);
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" width="20" height="20" strokeWidth="2">
                                <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"></path>
                                <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                <path d="M14 4l0 4l-6 0l0 -4"></path>
                            </svg>
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipSaveAll}>
                        <button type="submit"
                            className="text-xs btn-sm btn-green"
                            style={{ padding: '4px', borderRadius: '0.125rem' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" width="20" height="20" strokeWidth="2">
                                <path stroke="none" d="M0 0h24v24H0z"></path>
                                <path d="M7 9.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                <path d="M4.012 16.737a2 2 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                <path d="M11 14h6"></path> <path d="M14 11v6"></path>
                            </svg>
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipSaveExit}
                    >
                        <button type="button"
                            className="text-xs btn-sm btn-green"
                            style={{ padding: '4px', borderRadius: '0.125rem' }}
                            onClick={() => setEditVisible({ row: null, col: null })}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" width="20" height="20" strokeWidth="2">
                                <path d="M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z"></path>
                                <path d="M9 9l6 6m0 -6l-6 6"></path>
                            </svg>
                        </button>
                    </OverlayTrigger>
                </div>
            </form >
        </div >
    );


    const handleChangeInputValue = (name, id) => {
        setChangeString({ name, id });
    };


    //Función que cambie el valor de  una celda concreta de string a otro que pongas en el input 
    const handleApplyOne = (rowIndex, colIndex, id) => {
        if (formRef.current.checkValidity()) { // Validar los campos
            formRef.current.reportValidity(); // Mostrar mensajes del navegador si no son válidos
            if (changeString != undefined) {
                let copiaArray = JSON.parse(JSON.stringify(formatDataRef.current));
                copiaArray[rowIndex][colIndex] = {
                    ...copiaArray[rowIndex][colIndex],
                    name: changeString.name,
                    id: id
                };
                handleFormDataChange(copiaArray);
                setChangeString(undefined);
            }
            setEditVisible({ row: null, col: null });
        } else {
            formRef.current.reportValidity(); // Mostrar mensajes del navegador
        }


    }

    //Función que cambie el valor de todos los valores iguales a otro que pongas en el input 
    const handleApplyAll = (nameConcept, targetId, colIndexProp) => {
        if (changeString !== undefined) {
            let copiaArray = JSON.parse(JSON.stringify(formatDataRef.current));
            const trimmedNameConcept = String(nameConcept)?.trim();
            copiaArray.forEach(row => {
                row.forEach((cell, colIndex) => {
                    const cellName = String(cell.name)?.trim();
                    if (cell.label === targetId && cellName === trimmedNameConcept && colIndex == colIndexProp) {
                        cell.name = changeString.name;
                        cell.id = changeString.id;
                    }
                });
            });
            handleFormDataChange(copiaArray);
            setChangeString(undefined);

        }
    };

    return (
        <div>
            {editVisible.row === rowIndex && editVisible.col === colIndex
                ? renderEditMode()
                : renderReadMode()}
        </div>
    );
};
