import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import logo from '../../../images/Logo verde_airCO2.png';
import UserService from '../../../services/UserService';
import Header from '../Header';
import Sidebar from '../Sidebar';
import CSRDContent from './CSRDContent';
import CSRDInstructionsMenu from './CSRDInstructionsMenu';
import CSRDQuestionMenu from './CSRDQuestionMenu';

export default function CSRDPage() {
    const [t, i18n] = useTranslation("global");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [groupedQuestions, setGroupedQuestions] = useState({ data: [], isFetching: true });
    const [selectedGroup, setSelectedGroup] = useState(null);

    const useMediaQuery = (query) => {
        const [matches, setMatches] = useState(window.matchMedia(query).matches);

        useEffect(() => {
            const mediaQueryList = window.matchMedia(query);
            const documentChangeHandler = () => setMatches(mediaQueryList.matches);
            mediaQueryList.addListener(documentChangeHandler);

            return () => {
                mediaQueryList.removeListener(documentChangeHandler);
            };
        }, [query]);

        return matches;
    };
    const desktop = useMediaQuery('(min-width: 768px)');



    const [questionsPanelOpen, setQuestionsPanelOpen] = useState({ open: false, fixed: false });
    useEffect(() => {
        if (!desktop) {
            setQuestionsPanelOpen({ open: false, fixed: false })
        } else {
            setQuestionsPanelOpen({ open: true, fixed: true })
        }

    }, [desktop])

    const [instructionsPanelOpen, setInstructionsPanelOpen] = useState({ open: false, fixed: false });
    useEffect(() => {
        if (!desktop) {
            setInstructionsPanelOpen({ open: false, fixed: false })
        } else {
            setInstructionsPanelOpen({ open: false, fixed: true })
        }

    }, [desktop]);

    useEffect(() => {
        const fetchResume = async () => {
            const url = `${process.env.REACT_APP_CSRD}categories/resume`

            try {
                setGroupedQuestions({ data: [], isFetching: true });
                const response = await axios.get(`${url}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setGroupedQuestions({ data: response.data, isFetching: false });
            } catch (exception) {
                setGroupedQuestions({ data: [], isFetching: false });
            }

        };
        fetchResume();
    }, []);



    return (<div className=" sec-neutro-background flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden" id="mainDivFormPage">
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {groupedQuestions.isFetching && <>

                <div className=" h-screen flex justify-center items-center sec-neutro-background ">
                    <div className="relative ">
                        <img src={logo} alt="logo" className='mx-auto' style={{ width: "323px" }} />

                        <div className="text-center text-xl absolute w-full font-semibold sec-grey-text mb-4" style={{ top: "150px" }}>
                            CSRD
                        </div>

                        <div className="flex justify-center items-center absolute" style={{ top: "85px", left: "191px" }}>
                            <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-airco2-secondary " role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>}


            {!groupedQuestions.isFetching &&
                <main className='flex flex-col md:flex-row w-full justify-between relative' style={{ height: desktop ? 'calc(100vh - 64px)' : '' }}>

                    <CSRDQuestionMenu
                        questionsPanelOpen={questionsPanelOpen}
                        setQuestionsPanelOpen={setQuestionsPanelOpen}
                        selectedGroup={selectedGroup}
                        setSelectedGroup={setSelectedGroup}
                        desktop={desktop}
                        groupedQuestions={groupedQuestions} />


                    <CSRDContent
                        questionsPanelOpen={questionsPanelOpen}
                        instructionsPanelOpen={instructionsPanelOpen}
                        desktop={desktop}
                    />

                    <CSRDInstructionsMenu
                        instructionsPanelOpen={instructionsPanelOpen}
                        setInstructionsPanelOpen={setInstructionsPanelOpen}
                        desktop={desktop}
                        isFetching={false}
                        description={""}
                        instructions={""} />

                    {!desktop &&
                        <Modal show={instructionsPanelOpen.open} animation={false} data-aos="fade-up">
                            <Modal.Header className='sec-green-background'>
                                <Modal.Title>{t("footPrint.descripcionInstrucciones")}</Modal.Title>
                                <div className="flex justify-end">
                                    <button onClick={() => setInstructionsPanelOpen({ open: false, fixed: false })}>
                                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                                    </button>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='p-2'>
                                    <p className='font-bold'>{t('footPrint.descripcion')}</p>
                                    {/* <span className='text-sm italic' dangerouslySetInnerHTML={{ __html: questBlock?.data?.data?.description }} /> */}
                                </div>
                                <div className='p-2'>
                                    <p className='font-bold'>{t('footPrint.instrucciones')}</p>
                                    {/* <span className='text-sm italic' dangerouslySetInnerHTML={{ __html: questBlock?.data?.data?.instructions }} /> */}
                                </div>
                            </Modal.Body>

                        </Modal>
                    }
                </main>
            }
        </div>
    </div>)
}