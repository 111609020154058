import React from 'react'
import { useTranslation } from 'react-i18next'
import calculado from '../../../images/2024_sellos_calculado.webp'
import fondo from '../../../images/fondogp.png'
import imgBagsCustomer1 from '../../../images/img-cliente-bolsas1.svg'
import imgBagsCustomer2 from '../../../images/img-cliente-bolsas2.svg'
import logogp from '../../../images/LOGO GP BLANCO.COM.png'
import Header from '../../../partials/Header'
import LandingGreatPackaging from './landingGreatPackaging'

export default function gp() {
    const [t, i18n] = useTranslation("global");
    return (<>
        <div className='hidden'><Header></Header></div>
        <div className='background'>
            <div className='flex flex-col' style={{
                backgroundImage: `url(${fondo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: 'cover',
            }}>
                <div className='flex justify-start pt-4 px-4 items-center'>
                    <div data-aos="flip-left" data-aos-delay="400"> <img className='logoGp' src={logogp} style={{ maxWidth: "130px" }} alt="GP" /></div>
                    {/* <div data-aos="flip-left" data-aos-delay="400"><img className='logoAirco2' src={calculado} style={{ maxWidth: "100px", maxHeight: "100px" }} alt="airCO2" /></div> */}
                </div>
                <div className='flex justify-center my-4' style={{ alignItems: 'center' }} data-aos="flip-down" data-aos-delay="400">
                    <div className="transparent-text px-2" >

                        <h1>
                            <div className='bordered-text-sm'>GREAT PACKAGING</div>
                            <div className='bordered-text'>{t("gp.title1")}</div>
                            <div className='bordered-text'>{t("gp.title2")}</div>
                        </h1>

                    </div>
                </div>
                <div className='flex justify-start pb-4 px-2  '>
                    <div className="col-12 col-md-2">
                        <div className="flex gap-4 justify-start">
                            <div data-aos="flip-left" data-aos-delay="400"><img
                                src={imgBagsCustomer1}
                                alt="img-bags"
                                style={{ maxHeight: "80px", minWidth: "80px" }}
                            /></div>
                            <div data-aos="flip-left" data-aos-delay="400"><img
                                src={imgBagsCustomer2}
                                alt="img-bags"
                                style={{ maxHeight: "80px", minWidth: "80px" }}
                            /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <LandingGreatPackaging></LandingGreatPackaging>
            </div>
            {/* <div className="container-great-packaging xl:mx-8 mt-4">
                <div className='flex justify-center' >
                    <img className='w-full max-w-3xl' src={imgFooter2}></img>
                </div>
            </div> */}
        </div>
    </>)
}
