import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import UserService from "../../../../services/UserService";
import ImportExcelUploadFileOrData from "../ImportExcelUploadFileOrData";
import ModalMatchColumns from "../columnMappingForm";
import VehicleFleetExcelValidateData from "./VehicleFleetExcelValidateData";
import { Spinner } from "react-bootstrap";

export default function VehicleFleetExcelImport({ updateVehicles, setModalExcelImport }) {
    const [t, i18n] = useTranslation("global");
    const [excelData, setExcelData] = useState([]);
    const inputs = [
        { label: "identifier", title: "Identificador", type: "STRING", required: true },
        { label: "license", title: "Matrícula", type: "STRING", required: false },
        { label: "organizationId", title: "Organización", type: "ORGANIZATIONS", required: true },
        { label: "vehiclesMachineryId", title: "Tipo de vehículo", type: "VEHICLES", required: false },
        { label: "combustibleId", title: "Tipo de combustible", type: "COMBUSTIBLE", required: true },
        { label: "averageConsumption", title: "Consumo medio (l/100 km)", type: "NUMBER", required: true },
        { label: "emptyConsumption", title: "Consumo medio vacío (l/100 km)", type: "NUMBER", required: true },
        { label: "mma", title: "Masa Máxima Autorizada", type: "NUMBER", required: true },
        { label: "payload", title: "Carga útil (t)", type: "NUMBER", required: true },
        { label: "isActive", title: "¿Activo?", type: "BOOLEAN", required: false },
        { label: "isOwned", title: "¿Es de tu propiedad?", type: "BOOLEAN", required: false },
        { label: "isRefrigerated", title: "¿Refrigerado?", type: "BOOLEAN", required: false },
        { label: "additionalConsumption", title: "Consumo adicional", type: "NUMBER", required: false },
        { label: "refrigerationType", title: "Tipo de refrigeración", type: "REFRIGERATIONTYPE", required: false },
        { label: "refrigerationUnitType", title: "Tipo de unidad de refrigeración", type: "REFRIGERATIONUNITTYPE", required: false },
    ];

    const [processStateIndex, setProcessStateIndex] = useState(1);
    const [formatData, setFormatData] = useState([]);


    const processStates = [{ index: 1, title: t("footPrint.vehicleFleet.excelBreadcums1") },
    { index: 2, title: t("footPrint.vehicleFleet.excelBreadcums2") },
    { index: 3, title: t("footPrint.vehicleFleet.excelBreadcums3") }];

    const [spinnerDownloadExcel, setSpinnerDownloadExcel] = useState(false);


    const [organizations, setOrganizations] = useState([]);
    const [refrigerationType, setRefrigerationType] = useState([]);
    const [refrigerationUnitType, setRefrigerationUnitType] = useState([]);
    const [vehiclesCombustible, setVehiclesCombustible] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [organizationsResponse, refrigerationTypeResponse, refrigerationUnitTypeResponse, vehiclesCombustibleResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_CH_ORGANIZATIONS}?userId=${UserService.getUserId()}`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${UserService.getToken()}`
                        }
                    }),
                    await axios.get(`${process.env.REACT_APP_CH_REFRIGERATION_TYPE}`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${UserService.getToken()}`
                        }
                    }),
                    axios.get(`${process.env.REACT_APP_CH_REFRIGERATION_UNIT_TYPE}`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${UserService.getToken()}`
                        }
                    }),
                    axios.get(`${process.env.REACT_APP_CH_VEHICLES_COMBUSTIBLE}`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${UserService.getToken()}`
                        }
                    })
                ]);

                // Procesar datos de organizaciones
                const orgData = organizationsResponse.data.data
                    .map(org => ({ id: org.id, name: org.name }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                setOrganizations(orgData);

                const refrigerationTypeData = refrigerationTypeResponse.data.data
                    .sort((a, b) => a.name.localeCompare(b.name));

                setRefrigerationType(refrigerationTypeData);

                const refrigerationTypeUnitData = refrigerationUnitTypeResponse.data.data
                    .sort((a, b) => a.name.localeCompare(b.name));

                setRefrigerationUnitType(refrigerationTypeUnitData);

                const vehiclesCombustibleData = vehiclesCombustibleResponse.data.data;

                setVehiclesCombustible(vehiclesCombustibleData);
            } catch (error) {
                console.error("Error fetching data:", error);
                setOrganizations([]);
                setCountries([]);
                setRefrigerationType([]);
                setRefrigerationUnitType([]);
                setVehiclesCombustible([]);
            }
        };

        fetchData();
    }, []);

    const handleGetExcelDataFromStep1 = (processedExcelData) => {
        setExcelData(processedExcelData);
        setProcessStateIndex(2);
    }

    const handleColumnMappingSubmit = (mappings) => {
        if (mappings.length > 0) {
            setFormatData(dataFormater(mappings))
            setProcessStateIndex(3);
        } else {
            handleResetExcelComponent();
        }
    };


    const handleResetExcelComponent = () => {
        setExcelData([])
        setProcessStateIndex(1);
    }
    const [totalUploads, setTotalUploads] = useState(0);
    const handleFinishUploadOk = async (totalRowsUploads) => {
        try {
            await new Promise(resolve => {
                updateVehicles();
                setProcessStateIndex(4);
                setTotalUploads(totalRowsUploads);
                resolve()
            });
        } catch (error) {
            console.error('Error en handleFinishUploadOk', error)
        }
    }

    //Transformar la data de excel 
    const dataFormater = (mappings) => {
        if (excelData.length === 0) {
            return [];
        }
        let formattedData = excelData.slice(1).map((row) => {
            let formattedRow = Array(inputs.length);
            for (let i = 0; i < mappings.length; i++) {
                let obj = mappings[i];
                if (obj && typeof obj.index !== undefined) {
                    formattedRow[i] = { name: row[obj.index], label: inputs[i].label, valid: false };
                } else {
                    formattedRow[i] = { name: "", label: inputs[i].label, valid: false };
                }
            }
            return formattedRow;
        });
        return formattedData;
    };

    const fetchDownloadExcelTemplate = async () => {
        setSpinnerDownloadExcel(true);

        let url = `${process.env.REACT_APP_PY_CREATE_EXCEL_GENERAL}?name=${t("footPrint.vehicleFleet.excelTemplateName")}`;

        const dataInputs = inputs.map((input) => {
            if (input.type === "REFRIGERATIONTYPE") {
                return {
                    ...input,
                    options: refrigerationType,
                };
            } else if (input.type === "REFRIGERATIONUNITTYPE") {
                return {
                    ...input,
                    options: refrigerationUnitType,
                };
            } else if (input.type === "ORGANIZATIONS") {
                return {
                    ...input,
                    options: organizations,
                };
            } 
            else if (input.type === "VEHICLES") {
                return {
                    ...input,
                    options: vehiclesCombustible,
                };
            } else if (input.type === "COMBUSTIBLE") {
                return {
                    ...input,
                    options: vehiclesCombustible,
                };
            }
            return input;
        });

        let data = { "inputs": dataInputs };

        try {
            const response = await axios.post(url, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                responseType: 'blob',  // Especifica que esperas un archivo binario (Blob)
            });

            // Crear una URL temporal para el Blob (archivo)
            const fileURL = window.URL.createObjectURL(response.data);

            // Crear un enlace temporal para activar la descarga
            const a = document.createElement('a');
            a.href = fileURL;
            a.download = `${t("footPrint.vehicleFleet.excelTemplateName")}.xlsx`;  // Asigna el nombre del archivo
            document.body.appendChild(a);
            a.click();  // Simula un clic para descargar
            a.remove();  // Elimina el enlace después de la descarga

            // Limpiar la URL del Blob
            window.URL.revokeObjectURL(fileURL);

        } catch (exception) {
            console.error("Error al obtener el archivo:", exception);
                    setSpinnerDownloadExcel(false);
        }
        setSpinnerDownloadExcel(false);
    };


    return <div className="flex h-full flex-col">
        <div className=" flex flex-wrap justify-start w-full gap-4 bg-white pt-2 pb-4 z-80" style={{ position: 'fixed', top: '65.5px' }}>
            {
                processStates.map((v, i) => {
                    return (
                        <div className="flex items-center gap-4" key={i}>
                            <div className={` text-lg ${processStateIndex >= v.index ? "font-semibold cursor-pointer" : ""} `}
                                onClick={() => processStateIndex > v.index ? setProcessStateIndex(v.index) : ""}
                            >
                                {v.title}
                            </div>
                            {!(i == processStates.length - 1) &&
                                <div>&gt;</div>}
                        </div>
                    )
                })
            }
        </div>
        <div className="flex flex-col h-full" style={{ marginTop: "50px", marginBottom: "20px" }}>
            {(organizations.length == 0 || refrigerationType.length == 0 || refrigerationUnitType.length == 0
                || vehiclesCombustible.length == 0) &&
                <div className="flex flex-col items-center w-full h-full justify-center text-center">
                    <Spinner animation="border" variant="dark" size="lg" />
                    <div><p>{t("footPrint.vehicleFleet.chargingExcel")}</p></div>
                </div>}

            {organizations.length > 0 && refrigerationType.length > 0 && refrigerationUnitType.length > 0 &&
                vehiclesCombustible.length > 0 &&
                <>
                    {processStateIndex == 1 && <>
                        <div className='flex justify-end'>
                            <button onClick={() => fetchDownloadExcelTemplate()}
                                className={"btn-sm btn-green text-xs"}
                                disabled={spinnerDownloadExcel}>
                                {spinnerDownloadExcel ?
                                    <Spinner animation="border" variant="light" size="sm" /> :
                                    <>{t("footPrint.vehicleFleet.downloadClientExcelTemplate")}</>
                                }
                            </button>
                        </div>
                        <div className="mt-4">
                            <ImportExcelUploadFileOrData
                                excelHeaders={inputs.map(i => i.title)}
                                handleGetExcelData={handleGetExcelDataFromStep1} />
                        </div>
                    </>}

                    {processStateIndex == 2 && <ModalMatchColumns
                        userColumns={excelData[0]}
                        predefinedColumns={inputs.map(i => i.title)}
                        predefinedColumnsRequired={inputs.filter(f => f.required).map(i => i.title)}
                        onMappingSubmit={handleColumnMappingSubmit}
                        userFirstLineData={excelData[1]}
                        terceros={false}
                    />}

                    {processStateIndex == 3 && <VehicleFleetExcelValidateData
                        data={formatData}
                        handleResetExcelComponent={handleResetExcelComponent}
                        inputs={inputs}
                        organizationsList={organizations}
                        refrigerationType={refrigerationType}
                        refrigerationUnitType={refrigerationUnitType}
                        vehiclesCombustible={vehiclesCombustible}
                        handleFinishUploadOk={handleFinishUploadOk}

                    />}
                    {processStateIndex == 4 && <div className="flex flex-col items-center w-full h-full justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke=" var(--airco2-green-color)" strokeLinecap="round" strokeLinejoin="round" width="85" height="85" strokeWidth="2">
                            <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"></path>
                            <path d="M9 12l2 2l4 -4"></path>
                        </svg>
                        <div className="m-3 text-center">
                            <p className="font-bold text-lg">{t("footPrint.vehicleFleet.uploadExcelOk")}</p>
                            <p>{t("footPrint.vehicleFleet.totalUploads")} {totalUploads}</p>
                        </div>
                        <div> <button onClick={() => setModalExcelImport(false)} className={"btn-sm btn-green text-xs"} >
                            {t("footPrint.vehicleFleet.goback")}
                        </button>
                        </div>
                    </div>}

                </>
            }
        </div>

    </div>
}