import React, { useContext, useEffect, useState, useMemo } from 'react';

import axios from 'axios';
import { Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IdiomContext } from "../../../../context/createcontext";
import UserService from '../../../../services/UserService';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import ModalClientExcelImport from './ModalClientExcelImport';

export default function ClientListComponent({ openInModal, handleOpenEditNewWindow }) {

    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();
    const [showInstructions, setShowInstructions] = useState(false);
    const [deleteClient, setDeleteClient] = useState({ show: false, client: undefined });

    const [clients, setClients] = useState({
        data: [],
        isFetching: true
    });


    const fetchClients = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_CH_CLIENT}s?userId=${UserService.getUserId()}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setClients({ data: response.data, isFetching: false });

        } catch (exception) {
            setClients({ data: [], isFetching: false });
            console.error(exception.message)
        }
    };

    useEffect(() => {
        fetchClients();
    }, [])

    const updateClients = () =>{
        fetchClients();
    }


    const InstructionsText = () => {
        return <>
            <div className="mb-6">
                <ol style={{ listStyle: "", paddingLeft: "2rem", lineHeight: "2" }}>
                    <li><span>{t("clients.instructions.line2")}</span></li>
                    <li><span>{t("clients.instructions.line3")}</span></li>
                    <li><span>{t("clients.instructions.line4")}</span></li>
                </ol>
            </div>
        </>
    }

    const handleDelete = async (id) => {
        setDeleteClient({ show: false, client: deleteClient.client });
        try {
            const loadingToast = toast.loading(t("clients.eliminando"));

            const response = await axios.delete(`${process.env.REACT_APP_CH_CLIENT}/${id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });

            if (response.data.success) {
                toast.success(t("clients.eliminado"), { id: loadingToast });
            } else {
                toast.error(t("clients.error"), { id: loadingToast });
            }

            setClients(prevState => {
                const newData = prevState.data.data.filter(client => client.id !== id);
                return { ...prevState, data: { ...prevState.data, data: newData } };
            });

        } catch (error) {
            console.error('Error al eliminar:', error);
        } finally {
            setDeleteClient({ show: false, client: undefined });
        }


    }


    const useSortableData = (items, config = null) => {
        const [sortConfig, setSortConfig] = useState(config);

        const sortedItems = useMemo(() => {
            if (!items || items.length === 0) {
                return [];
            }

            let sortableItems = [...items];

            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    let valueA = a[sortConfig.key];
                    let valueB = b[sortConfig.key];

                    if (sortConfig.key.includes(".")) {
                        let params = sortConfig.key.split(".");
                        valueA = a[params[0]]?.[params[1]];
                        valueB = b[params[0]]?.[params[1]];
                    }

                    if (valueA === null || valueA === undefined || valueA === "") {
                        return sortConfig.direction === "ascending" ? -1 : 1;
                    }

                    if (valueB === null || valueB === undefined || valueB === "") {
                        return sortConfig.direction === "ascending" ? 1 : -1;
                    }

                    if (!isNaN(valueA) || moment(valueA).isValid()) {
                        if (valueA < valueB) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }

                        if (valueA > valueB) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    } else {
                        if (valueA.toString().toLowerCase() < valueB.toString().toLowerCase()) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }

                        if (valueA.toString().toLowerCase() > valueB.toString().toLowerCase()) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    }
                    return 0;
                });
            }

            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = "ascending";
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === "ascending"
            ) {
                direction = "descending";
            }
            setSortConfig({ key, direction });
        };
        return { items: sortedItems, requestSort, sortConfig };
    };

    const { items, requestSort, sortConfig } = useSortableData(
        clients.data.data
    );

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const DropdownButtonAddClients = ({ t }) => {
        const [isOpen, setIsOpen] = useState(false);
        const navigate = useNavigate();

        const toggleDropdown = () => {
            setIsOpen(!isOpen);
        };

        const handleOptionClick = (path) => {
            navigate(path);
            setIsOpen(false);
        };

        return (
            <div className="relative inline-block">
                <button
                    className="btn-sm btn-green px-4 py-2"
                    onClick={toggleDropdown}
                >
                    {t("clients.añadir")}
                    <span className={`ml-2 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="1">
                            <path d="M6 9l6 6l6 -6"></path>
                        </svg>
                    </span>
                </button>
                {isOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10">
                        <ul className="py-1">
                            <li>
                                <button
                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                    onClick={() => handleOptionClick('/footprint/client')}
                                >
                                    {t("clients.byForm")}
                                </button>
                            </li>
                            <li>
                                <button
                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                    onClick={() => setModalExcelImport(true)}
                                >
                                    {t("clients.byExcel")}
                                </button>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        );
    };


    const [modalExcelImport, setModalExcelImport] = useState(false)

    return (

        <div>

            <Toaster
                position="bottom-right"
                reverseOrder={false}
                toastOptions={{
                    duration: 8000
                }}
            />
            <ModalClientExcelImport
                modalExcelImport={modalExcelImport}
                setModalExcelImport={setModalExcelImport}
                updateClients={updateClients} />

            <Modal show={showInstructions} size={"lg"}>
                <Modal.Header className="sec-neutro-background">
                    <Modal.Title className="text-airco2-general-grey">
                        {t("certificaciones.instrucciones.instruction")}
                    </Modal.Title>
                    <div className="flex justify-end">
                        <button onClick={() => setShowInstructions(false)}>
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <h2 className="text-2xl text-center text-gray-800 dark:text-gray-100 font-bold mb-2">
                        {t("certificaciones.instrucciones.title")}
                    </h2>
                    <InstructionsText></InstructionsText>
                </Modal.Body>
            </Modal>

            <Modal show={deleteClient.show}>
                <Modal.Header className="sec-neutro-background">
                    <Modal.Title className="text-airco2-general-grey">
                        {t("clients.eliminar")}
                    </Modal.Title>
                    <div className="flex justify-end">
                        <button onClick={() => setDeleteClient({ show: false, client: undefined })}>
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center text-airco2-general-grey'>
                        <h3 className='text-xl'>{t("clients.eliminarSeguro")}</h3>
                        <p className='mt-2 font-semibold'>{deleteClient.client?.companyName}</p>
                        <button className='btn btn-sm btn-green text-white mt-4' onClick={() => handleDelete(deleteClient.client?.id)}>{t("fpThird.eliminar")}</button>
                    </div>
                </Modal.Body>
            </Modal>

            {!openInModal &&
                <div className='flex justify-end gap-2 mb-4'>
                    {clients.isFetching || (clients?.data?.data?.length !== undefined && clients?.data?.data?.length > 0) &&
                        <button onClick={() => setShowInstructions(true)} className='flex items-center btn-sm btn-banner-o mr-4 px-4 py-2' >
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-square-rounded mr-2" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M12 9h.01" />
                                <path d="M11 12h1v4h1" />
                                <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                            </svg>
                            {t("certificaciones.instrucciones.instruction")}
                        </button>}

                    <DropdownButtonAddClients t={t}></DropdownButtonAddClients>
                </div>
            }

            <div className=''>
                {clients.isFetching ? (
                    <div className='flex justify-center'>
                        <Spinner className='mt-4' animation="border" variant="dark" />
                    </div>
                ) : (
                    (clients?.data?.data?.length !== undefined && clients?.data?.data?.length !== 0) ?
                        <div className="container-simple-table-sm w-full">
                            <table className="simple-table-sm">
                                <thead className='text-center text-xl sec-green-background text-white'>
                                    <tr className='py-4'>
                                        <th>
                                            <button type="button" onClick={() => requestSort('identifier')}
                                                className={`${getClassNamesFor('identifier')} text-underline`}>
                                                {t("clients.identificador")}
                                            </button>
                                        </th>
                                        <th>
                                            <button type="button" onClick={() => requestSort('cif')}
                                                className={`${getClassNamesFor('cif')} text-underline`}>
                                                {t("clients.cif")}
                                            </button>
                                        </th>
                                        <th>
                                            <button type="button" onClick={() => requestSort('companyName')}
                                                className={`${getClassNamesFor('companyName')} text-underline`}>
                                                {t("clients.nombre")}
                                            </button>
                                        </th>
                                        <th>
                                            <button type="button" onClick={() => requestSort('region')}
                                                className={`${getClassNamesFor('region')} text-underline`}>
                                                {t("clients.provincia-pais")}
                                            </button>
                                        </th>
                                        <th>
                                            <button type="button" onClick={() => requestSort('organizationDtoList')}
                                                className={`${getClassNamesFor('organizationDtoList')} text-underline`}>
                                                {t("clients.organizaciones")}
                                            </button>
                                        </th>
                                        <th>
                                            {t("clients.acciones")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    {clients.data.length > 0 ? <tr className='flex justify-center'> <Spinner animation="border" variant="dark" /> </tr> :
                                        clients.data.data != undefined && items.map((client) => {

                                            return (
                                                <tr key={client.identifier}>
                                                    <td>{client.identifier}</td>
                                                    <td>{client.cif}</td>
                                                    <td>{client.companyName}</td>
                                                    <td>{client.region + ", " + client.country}</td>
                                                    <td>{client.organizationDtoList.map((org, i) => {
                                                        return ((client.organizationDtoList.length > 1 && client.organizationDtoList.length - 1 != i) ? (org.name + ", ") : (org.name + " "));
                                                    })
                                                    }
                                                    </td>
                                                    <td>
                                                        <div className='flex items-center justify-center gap-2'>

                                                            <button onClick={() => {
                                                                if (openInModal) {
                                                                    handleOpenEditNewWindow("form", client.id);
                                                                } else {
                                                                    navigate(`/footprint/client/${client.id}`)
                                                                }
                                                            }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                                                    <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                                                    <path d="M16 5l3 3" />
                                                                </svg>
                                                            </button>
                                                            <button onClick={() => setDeleteClient({ show: true, client: client })}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <line x1="4" y1="7" x2="20" y2="7" />
                                                                    <line x1="10" y1="11" x2="10" y2="17" />
                                                                    <line x1="14" y1="11" x2="14" y2="17" />
                                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            <div className="max-w-2xl m-auto mt-16">
                                <div className="text-center px-4">
                                    <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-gray-200 to-gray-100 dark:from-gray-700 dark:to-gray-800 mb-4">

                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-seeding" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 10a6 6 0 0 0 -6 -6h-3v2a6 6 0 0 0 6 6h3" stroke-dasharray="40" stroke-dashoffset="40">
                                                <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" />
                                            </path>
                                            <path d="M12 14a6 6 0 0 1 6 -6h3v1a6 6 0 0 1 -6 6h-3" stroke-dasharray="40" stroke-dashoffset="40">
                                                <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" begin="3s" />
                                            </path>
                                            <path d="M12 20l0 -10" stroke-dasharray="10" stroke-dashoffset="10">
                                                <animate attributeName="stroke-dashoffset" from="10" to="0" dur="3s" repeatCount="indefinite" begin="5s" />
                                            </path>
                                        </svg>
                                    </div>
                                    <h2 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-2">{t("clients.instructions.title")}</h2>
                                    <div className="mb-6">
                                        <p>{t("clients.instructions.line1")}</p>
                                        <InstructionsText></InstructionsText>
                                    </div>
                                </div>
                            </div>
                        </div>
                )
                }
            </div>
        </div>

    )
}



