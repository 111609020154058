import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import VehicleFleetExcelImport from "./VehicleFleetExcelImport";

export default function ModalVehicleFleetExcelImport({ modalExcelImport, setModalExcelImport, updateVehiclesFleet }) {
    const [t, i18n] = useTranslation("global");


    return <Modal show={modalExcelImport} className='xl-modal' animation={false} data-aos="fade-up" fullscreen backdropClassName="xl-modal-backshadow">
        <ModalHeader className='xl-modal-header sec-neutro-background sec-grey-text text-lg flex justify-between '>
            <ModalTitle className=''>{t("footPrint.modalExcelTitle")}</ModalTitle>
            <button onClick={() => setModalExcelImport(!modalExcelImport)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                </svg>
            </button>
        </ModalHeader>
        <ModalBody className='xl-modal-body'>

            <VehicleFleetExcelImport
                updateVehicles={updateVehiclesFleet}
                setModalExcelImport={setModalExcelImport} />

        </ModalBody>
        {/* <ModalFooter className="flex justify-center">
            <button className="btn-sm px-4 py-2 btn-cancel text-sm" onClick={() => setModalExcelImport(false)}>
                {t("footPrint.cerrar")}
            </button>
        </ModalFooter> */}
    </Modal>
}