import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserService from '../../../../services/UserService';
import VehicleFleetInstructions from './VehicleFleetInstructions';
export default function VehiclesFleetList({ openInModal, handleOpenEditNewWindow, uniqueUpdate }) {
    const navigate = useNavigate();

    const [t, i18n] = useTranslation("global");

    const [deleteSpinner, setDeleteSpinner] = useState({ status: false, id: -1 })
    const [modalConfirmDelete, setModalConfirmDelete] = useState({ view: false, id: "" });

    const [vehiclesFleet, setVehiclesFleet] = useState({
        data: [],
        isFetching: true
    });
    useEffect(() => {
        var fetchVehiclesFleet = async () => {
            try {
                setVehiclesFleet({ data: [], isFetching: true });
                const response = await axios.get(`${process.env.REACT_APP_CH_VEHICLE_FLEET}s?userId=${UserService.getUserId()}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setVehiclesFleet({ data: response.data.data, isFetching: false });

            } catch (exception) {
                setVehiclesFleet({ data: [], isFetching: false });
                console.error('Error fetching vehicle fleet data:', exception);
            }
        };
        fetchVehiclesFleet();
    }, [uniqueUpdate]);



    const useSortableData = (items, config = null) => {

        const [sortConfig, setSortConfig] = useState(config);
        const sortedItems = useMemo(() => {
            if (!items || items.length === 0) {
                return [];
            }
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    let valueA = a[sortConfig.key];
                    let valueB = b[sortConfig.key];

                    if (sortConfig.key.includes(".")) {
                        let params = sortConfig.key.split(".");
                        valueA = a[params[0]]?.[params[1]];
                        valueB = b[params[0]]?.[params[1]];
                    }

                    if (valueA === null || valueA === undefined || valueA === "") {
                        return sortConfig.direction === "ascending" ? -1 : 1;
                    }

                    if (valueB === null || valueB === undefined || valueB === "") {
                        return sortConfig.direction === "ascending" ? 1 : -1;
                    }

                    if (!isNaN(valueA) || moment(valueA).isValid()) {
                        if (valueA < valueB) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }
                        if (valueA > valueB) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    } else {
                        if (
                            valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
                        ) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }
                        if (
                            valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
                        ) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    }

                    return 0;
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };

        return { items: sortedItems, requestSort, sortConfig };
    };

    const { items, requestSort, sortConfig } = useSortableData(vehiclesFleet.data);

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const getIconYesNo = (value) => {
        if (value) {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="var(--airco2-green-color)" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="1.5">
                <path d="M5 12l5 5l10 -10"></path>
            </svg>
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="var(--airco2-orange-color)" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="2">
                <path d="M18 6l-12 12"></path>
                <path d="M6 6l12 12"></path>
            </svg>
        }
    }

    const handleDelete = (idVehicle, identifier) => {

        const fetchDeleteAnswer = async () => {
            setDeleteSpinner({ status: true, id: idVehicle })
            const url = `${process.env.REACT_APP_CH_VEHICLE_FLEET}/${idVehicle}`

            toast.promise(
                axios.delete(url, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                }).then((response) => {
                    if (response.data.success) {
                        let updatedArray = [...vehiclesFleet.data];
                        updatedArray = updatedArray.filter(item => item.id !== idVehicle);
                        setVehiclesFleet(prev => ({
                            ...prev,
                            data: updatedArray
                        }));
                        return response.data;
                    } else {
                        throw new Error(response.data.message || 'Error inesperado');
                    }
                }),
                {
                    loading: t("footPrint.vehicleFleet.deleting"),
                    success: <b>{t("footPrint.vehicleFleet.deleted")} {identifier}.</b>,
                    error: <b>{t("footPrint.vehicleFleet.error")}</b>,
                }
            );
            setDeleteSpinner({ status: false, id: -1 })
        };
        fetchDeleteAnswer();
        setModalConfirmDelete({ view: false, id: "" })
    }

    const renderTooltipEdit = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.editar")}
        </Tooltip>
    );

    const renderTooltipDelete = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.eliminar")}
        </Tooltip>
    );

    return (<>
        <Toaster
            position="bottom-right"
            reverseOrder={false}
            toastOptions={{
                duration: 8000
            }}
        />

        <Modal show={modalConfirmDelete.view}>
            <ModalHeader className='sec-neutro-background sec-grey-text text-base font-semibold'>
                {t("footPrint.eliminar")}
            </ModalHeader>
            <ModalBody>
                <p className="text-center text-sm"> {t("footPrint.vehicleFleet.deletemodal").replace("{vehicle}", vehiclesFleet.data.find(item => item.id === modalConfirmDelete.id)?.identifier)} </p>
            </ModalBody>
            <div className="flex justify-center space-x-4 mb-4">
                <button className="btn-sm btn-cancel text-xs" onClick={() => setModalConfirmDelete({ view: false, id: "" })}> {t("footPrint.cancelar")}</button>
                <button className="btn-sm btn-green text-xs" onClick={() => handleDelete(modalConfirmDelete.id, vehiclesFleet.data.find(item => item.id === modalConfirmDelete.id)?.identifier)}> {t("footPrint.eliminar")}</button>
            </div>
        </Modal>

        {vehiclesFleet.isFetching &&
            <div className='text-center flex flex-col items-center'>
                <Spinner animation="border" variant="dark"></Spinner>
                {t("footPrint.vehicleFleet.chargingfleet")}
            </div>
        }

        {!vehiclesFleet.isFetching && vehiclesFleet.data.length === 0 &&
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                <div className="max-w-2xl m-auto mt-16">
                    <VehicleFleetInstructions></VehicleFleetInstructions>
                </div>
            </div>
        }
        {!vehiclesFleet.isFetching && vehiclesFleet.data.length > 0 &&
            <div className="container-simple-table w-full">
                <table className="simple-table-sm">
                    <thead className='text-center  text-xl sec-green-background text-white'>
                        <tr className='py-4'>
                            <th>
                                <button type="button" onClick={() => requestSort('identifier')}
                                    className={`${getClassNamesFor('identifier')} text-underline`}>
                                    {t("footPrint.vehicleFleet.identifier")}
                                </button>
                            </th>

                            <th>
                                <button type="button" onClick={() => requestSort('license')}
                                    className={`${getClassNamesFor('license')} text-underline`}>
                                    {t("footPrint.vehicleFleet.license")}
                                </button>
                            </th>

                            <th>
                                <button type="button" onClick={() => requestSort('organizationName')}
                                    className={`${getClassNamesFor('organizationName')} text-underline`}>
                                    {t("footPrint.organization")}
                                </button>
                            </th>

                            <th>
                                <button type="button" onClick={() => requestSort('vehiclesMachineryName')}
                                    className={`${getClassNamesFor('vehiclesMachineryName')} text-underline`}>
                                    {t("footPrint.vehicleFleet.transportType")}
                                </button>
                            </th>

                            <th>
                                <button type="button" onClick={() => requestSort('combustibleName')}
                                    className={`${getClassNamesFor('combustibleName')} text-underline`}>
                                    {t("footPrint.vehicleFleet.combustibleType")}
                                </button>
                            </th>

                            <th>
                                <button type="button" onClick={() => requestSort('isOwned')}
                                    className={`${getClassNamesFor('isOwned')} text-underline`}>
                                    {t("footPrint.vehicleFleet.isOwned")}
                                </button>
                            </th>

                            <th>
                                <button type="button" onClick={() => requestSort('isRefrigerated')}
                                    className={`${getClassNamesFor('isRefrigerated')} text-underline`}>
                                    {t("footPrint.vehicleFleet.isRefrigerated")}
                                </button>
                            </th>

                            <th className='text-end pr-4'>
                                {t("footPrint.vehicleFleet.actions")}
                            </th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {vehiclesFleet.data != undefined && items.length === 0 &&
                            <tr>
                                <td colSpan={"8"}> {t("footPrint.vehicleFleet.noData")}</td>
                            </tr>
                        }
                        {vehiclesFleet.data != undefined && items.map((vehicle) => {
                            return (
                                <tr key={vehicle.id}>
                                    <td>{vehicle.identifier}</td>
                                    <td>{vehicle.license}</td>
                                    <td>{vehicle.organizationName}</td>
                                    <td>{vehicle.vehiclesMachineryName}</td>
                                    <td>{vehicle.combustibleName}</td>
                                    <td><div className="flex align-center justify-center">{getIconYesNo(vehicle.isOwned)}</div></td>
                                    <td><div className="flex align-center justify-center">{getIconYesNo(vehicle.isRefrigerated)}</div></td>
                                    <td>
                                        <div className="flex space-x-2 justify-end mr-4 items-center">
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltipEdit}
                                            >
                                                <button onClick={() => {
                                                    if (openInModal) {
                                                        handleOpenEditNewWindow("form", vehicle.id);
                                                    } else {
                                                        navigate(`/footprint/vehicle-fleet/${vehicle.id}`)
                                                    }
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                                        <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                                        <path d="M16 5l3 3" />
                                                    </svg>
                                                </button>
                                            </OverlayTrigger>


                                            {(!deleteSpinner.status || deleteSpinner.id !== vehicle.id) &&
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipDelete}
                                                >
                                                    <button onClick={() => setModalConfirmDelete({ view: true, id: vehicle.id })}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <line x1="4" y1="7" x2="20" y2="7" />
                                                            <line x1="10" y1="11" x2="10" y2="17" />
                                                            <line x1="14" y1="11" x2="14" y2="17" />
                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                        </svg>
                                                    </button>
                                                </OverlayTrigger>


                                            }
                                            {deleteSpinner.status && deleteSpinner.id === vehicle.id && <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true" />}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                        }

                    </tbody>
                </table>
            </div>
        }
    </>
    )
}