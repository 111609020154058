import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import imgStripe from '../../../images/stripe_payments.svg';
import UserService from '../../../services/UserService';
import Header from '../Header';
import Sidebar from '../Sidebar';
import MPForestList from './MPForestList';
import MPOrdersList from './MPOrdersList';
import MPProvider from './MPProvider';
import { StripeButton } from './StripeButton';


function Account() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [infoProvider, setInfoProvider] = useState("");
  const [pestaña, setPestaña] = useState("montes");
  const [loading, setLoading] = useState(true);
  const [newAccountStripe, setNewAccountStripe] = useState({
    newAccountStripeData: [],
    isFetching: false
  });


  useEffect(() => {
    fetch(`${process.env.REACT_APP_MP_PRIVATE_PROVIDER}`, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${UserService.getToken()}`
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((infoProvider) => {
        if (!infoProvider.error) {
          setLoading(false)
          setInfoProvider(infoProvider);
        }
      });
  }, [newAccountStripe.newAccountStripeData])




  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {loading ? <span>  <div className='flex justify-center'><Spinner animation="border" /></div></span> :
              infoProvider.stripeSubmited == false || infoProvider.stripeId == null || infoProvider.stripeSubmited == null ?
                <div className="rounded-sm mb-8">
                  <div className=" md:flex md:-mr-px sec-neutro-background w-full">
                    <div className='ml-4'>
                      <p className='h5'>{t("marketplace.one_step")}</p>
                      <StripeButton>  </StripeButton>
                    </div>
                    <div className='mt-8'>
                      <img src={imgStripe}></img>
                    </div>
                  </div>
                </div>

                :
                <>
                  {/* Content */}
                  <div className="bg-white shadow-lg rounded-sm mb-8">
                    <div className="flex flex-col xxl:flex-row xxl:-mr-px">

                      {/* <MarketplaceSidebar /> */}
                      <div className="sec-neutro-background flex flex-nowrap overflow-x-auto no-scrollbar xxl:block xxl:overflow-auto px-3 py-2 border-b xxl:border-b-0 xxl:border-r border-gray-200 min-w-60 xxl:space-y-3">
                        {/* Group 1 */}
                        <div>
                          {/* <div className="text-xs font-semibold text-gray-400 uppercase mb-3">{t("account.configuracion")}</div> */}
                          <ul className="flex flex-nowrap xxl:block mr-3 xxl:mr-0">
                            <li className="mr-0.5 xxl:mr-0 xxl:mb-0.5">
                              <button onClick={() => setPestaña("montes")} className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap text-gray-400 ${pestaña.includes('montes') && 'bg-white'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`icon icon-tabler icon-tabler-shopping-cart mr-1 `} stroke={`${pestaña.includes('montes') ? 'var(--airco2-orange-color)': '#64748b'}`} width="24" height="24"
                                  viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                                  <line x1="15" y1="21" x2="15" y2="18" />
                                  <path d="M8 13l-2 -2" />
                                  <path d="M8 12l2 -2" />
                                  <path d="M8 21v-13" />
                                  <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                                </svg>

                                <span className={`text-sm font-medium ${pestaña.includes('montes') ? 'text-airco2-secondary' : 'hover:text-gray-700'}`}>{t("marketplace.panel_forest")}</span>
                              </button>
                            </li>
                            <li className="mr-0.5 xxl:mr-0 xxl:mb-0.5">
                              <button disabled={infoProvider == "" ? true : false} onClick={() => setPestaña("provider")} className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap text-gray-400   hover:bg-indigo-50 ${pestaña.includes('provider') && 'bg-white'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`icon icon-tabler icon-tabler-shopping-cart mr-1  hover:bg-indigo-50 ${pestaña.includes('provider') && 'text-airco2-secondary'} `} stroke={`${pestaña.includes('provider') ? 'var(--airco2-orange-color)': '#64748b'}`} width="24" height="24"
                                  viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <circle cx="12" cy="7" r="4" />
                                  <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                </svg>
                                <span className={`text-sm font-medium ${pestaña.includes('provider') ? 'text-airco2-secondary' : 'hover:text-gray-700'}`}>{t("marketplace.panel_provider")}</span>
                              </button>
                            </li>

                            <li className="mr-0.5 xxl:mr-0 xxl:mb-0.5">
                              <button onClick={() => setPestaña("orders")} className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap text-gray-400 ${pestaña.includes('orders') && 'bg-white'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`icon icon-tabler icon-tabler-shopping-cart mr-1 ${pestaña.includes('orders') && 'text-indigo-400'}`} stroke={`${pestaña.includes('orders') ? 'var(--airco2-orange-color)': '#64748b'}`} width="24" height="24"
                                  viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <circle cx="6" cy="19" r="2" />
                                  <circle cx="17" cy="19" r="2" />
                                  <path d="M17 17h-11v-14h-2" />
                                  <path d="M6 5l14 1l-1 7h-13" />
                                </svg>
                                <span className={`text-sm font-medium ${pestaña.includes('orders') ? 'text-airco2-secondary' : 'hover:text-gray-700'}`}>{t("marketplace.panel_orders")}</span>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>


                      {pestaña == "montes" &&
                        <MPForestList forestList={infoProvider.productProvider.sort((a, b) => new Date(b.updatedDate) - new Date(a.updatedDate))} />
                      }

                      {pestaña == "provider" &&
                        <MPProvider infoProvider={infoProvider} />
                      }

                      {pestaña == "orders" &&
                        <MPOrdersList orderList={infoProvider.orderLineProviderDtos.sort((a, b) => new Date(b.updatedDate) - new Date(a.updatedDate))} />
                      }

                    </div>

                  </div>
                </>
            }
          </div>
        </main>

      </div>

    </div>
  );
}

export default Account;