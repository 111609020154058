import axios from "axios";
import { BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import "moment/locale/es";
import "moment/locale/gl";
import "moment/locale/pt";
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../../context/createcontext";
import { getQuestion2DataUnit, getQuestionDataUnit } from "../../../../services/Functions";
import UserService from "../../../../services/UserService";
import { formatThousands } from '../../utils/Utils';


Chart.register(BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend, CategoryScale);
export default function EvolutionTable({ selectedOrganizacionId }) {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    const [calculationQuesitonEvolutions, setCalculationQuestionEvolutions] = useState({ data: undefined, groupedByBuilding: undefined, isFetching: false });
    const [alcanceActivo, setAlcanceActivo] = useState(1);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [allBuildings, setAllBuildings] = useState([]);


    useEffect(() => {
        const fetchCalculationEvolutionQuestions = async () => {
            setCalculationQuestionEvolutions({ data: undefined, groupedByBuilding: undefined, isFetching: true })

            let url = `${process.env.REACT_APP_CH_EVOLUTION_QUESTIONS}`;
            if (selectedOrganizacionId !== 'all') {
                url += `/organization/${selectedOrganizacionId}`
            }

            try {
                const response = await axios.get(`${url}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${UserService.getToken()}`,
                    },
                });

                let buildings = response?.data?.data?.calculationQuestionEvolutionSedeDtos || [];

                let groupedData = {};
                let buildingsNames = [];
                for (const building of buildings) {
                    const tablaScope1y2 = building.calculationQuestionEvolutionItemDtos.filter(
                        item => item.scope === 1 || item.scope === 2
                    );

                    const tablaScope3 = building.calculationQuestionEvolutionItemDtos.filter(
                        item => item.scope === 3
                    );
                    groupedData[building.organizationBuilding] = { scope1and2: tablaScope1y2, scope3: tablaScope3 };
                    buildingsNames.push(building.organizationBuilding);
                }

                if (buildings.length > 1) {
                    const allBuildingsLabel = (selectedOrganizacionId === "all" ? t("evolution.allOrganizations") : t("evolution.allOrganizationBuildings"));
                    const sumByQuestion = (items) => {
                        const map = new Map();

                        for (const item of items) {
                            const key = item.questionId;

                            if (!map.has(key)) {
                                map.set(key, {
                                    ...item,
                                    baseKgCO2e: 0,
                                    currentKgCO2e: 0,
                                    lastKgCO2e: 0,
                                    position: item.position,
                                    calculationQuestionEvolutionItemActivityDataDtos: new Map()
                                });
                            }

                            const existing = map.get(key);
                            existing.baseKgCO2e += item.baseKgCO2e || 0;
                            existing.currentKgCO2e += item.currentKgCO2e || 0;
                            existing.lastKgCO2e += item.lastKgCO2e || 0;

                            // Agregar TODOS los activityDataDtos (sumar si ya existen)
                            for (const activity of item.calculationQuestionEvolutionItemActivityDataDtos || []) {
                                const activityKey = activity.name;

                                if (!existing.calculationQuestionEvolutionItemActivityDataDtos.has(activityKey)) {
                                    existing.calculationQuestionEvolutionItemActivityDataDtos.set(activityKey, {
                                        name: activity.name,
                                        baseKgCO2e: activity.baseKgCO2e || 0,
                                        currentKgCO2e: activity.currentKgCO2e || 0,
                                        lastKgCO2e: activity.lastKgCO2e || 0,
                                        baseQuantity: activity.baseQuantity || 0,
                                        currentQuantity: activity.currentQuantity || 0,
                                        baseQuantity2: activity.baseQuantity2 || 0,
                                        currentQuantity2: activity.currentQuantity2 || 0
                                    });
                                } else {
                                    const existingActivity = existing.calculationQuestionEvolutionItemActivityDataDtos.get(activityKey);
                                    existingActivity.baseKgCO2e += activity.baseKgCO2e || 0;
                                    existingActivity.currentKgCO2e += activity.currentKgCO2e || 0;
                                    existingActivity.lastKgCO2e += activity.lastKgCO2e || 0;
                                    existingActivity.baseQuantity += activity.baseQuantity || 0;
                                    existingActivity.currentQuantity += activity.currentQuantity || 0;
                                    existingActivity.baseQuantity2 += activity.baseQuantity2 || 0;
                                    existingActivity.currentQuantity2 += activity.currentQuantity2 || 0;
                                }
                            }

                            // Asegurar que si hay preguntas sin datos de actividad, se inicializan vacías
                            if (!existing.calculationQuestionEvolutionItemActivityDataDtos.size) {
                                existing.calculationQuestionEvolutionItemActivityDataDtos = new Map();
                            }
                        }

                        // Convertir los mapas internos de actividades en arrays
                        return Array.from(map.values()).map(item => ({
                            ...item,
                            calculationQuestionEvolutionItemActivityDataDtos: Array.from(item.calculationQuestionEvolutionItemActivityDataDtos.values())
                        })).sort((a, b) => a.position - b.position);
                    };

                    buildings.unshift(allBuildingsLabel);
                    const generalScope1and2 = Object.values(groupedData).flatMap(buildingData => buildingData.scope1and2);
                    const generalScope3 = Object.values(groupedData).flatMap(buildingData => buildingData.scope3);

                    groupedData[allBuildingsLabel] = {
                        scope1and2: Object.values(sumByQuestion(generalScope1and2)),
                        scope3: Object.values(sumByQuestion(generalScope3))
                    };
                    buildingsNames.unshift(allBuildingsLabel);
                }

                setCalculationQuestionEvolutions({ data: response?.data?.data, groupedByBuilding: groupedData, isFetching: false })
                setAllBuildings(buildingsNames);
                setSelectedBuilding(buildingsNames[0])

            } catch (exception) {
                setCalculationQuestionEvolutions({ data: undefined, groupedByBuilding: undefined, isFetching: false })
                console.error(exception);
            }
        };

        fetchCalculationEvolutionQuestions();
    }, [selectedOrganizacionId]);




    const cambiarAlcance = (alcance) => {
        setAlcanceActivo(alcance);
    };

    return (
        <React.Fragment>
            {calculationQuesitonEvolutions?.isFetching && <div className="text-center"><Spinner className='' /></div>}
            {!calculationQuesitonEvolutions?.isFetching && calculationQuesitonEvolutions?.data && <>
                <div className="w-full bg-white rounded-sm border border-gray-200 p-4 pb-2 flex flex-col justify-content-center" >
                    <h3 className="text-center font-bold mb-4">{t("evolution.infoActividad")}</h3>
                    {allBuildings.length > 1 && <div className="mb-4">
                        <ul className="flex flex-wrap items-center justify-center -m-1">
                            {allBuildings.map((building, index) => <li className="m-1" key={`table-building-${building}`}>
                                <button onClick={() => setSelectedBuilding(building)} className={`${selectedBuilding === building ? 'sec-green-background text-white' : ' text-gray-500'} 
                                inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank shadow-sm duration-150 ease-in-out`}>
                                    {building}
                                </button>
                            </li>)}
                        </ul>
                    </div>}
                    <div className="mb-3">
                        <ul className="flex flex-wrap -m-1">
                            <li className="m-1">
                                <button onClick={() => cambiarAlcance(1)} className={`${alcanceActivo === 1 ? 'sec-orange-background text-white' : ' text-gray-500'}
    inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank-orange shadow-sm duration-150 ease-in-out`}>
                                    {t("evolution.scopes1and2")}
                                    <span className={`ml-2 px-2 py-1 rounded-full font-bold text-xs text-airco2-general
                                     ${alcanceActivo === 1 ? 'bg-white' : ' sec-orange-background  text-white'}`}>
                                        {calculationQuesitonEvolutions?.groupedByBuilding[selectedBuilding]?.scope1and2?.length}
                                    </span>
                                </button>
                            </li>
                            <li className="m-1">
                                <button onClick={() => cambiarAlcance(3)} className={`${alcanceActivo === 3 ? 'sec-orange-background  text-white' : ' text-gray-500'}
    inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank-orange shadow-sm duration-150 ease-in-out`}>
                                    {t("evolution.scope3")}
                                    <span className={`ml-2 px-2 py-1 rounded-full font-bold text-xs text-airco2-general
                                     ${alcanceActivo === 3 ? 'bg-white' : ' sec-orange-background  text-white'}`}>
                                        {calculationQuesitonEvolutions?.groupedByBuilding[selectedBuilding]?.scope3?.length}
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="container-simple-table">
                        <table className="simple-table">
                            <thead className="">
                                <tr>
                                    <th></th>
                                    <th> <div className="text-left">{t("evolution.source")}</div></th>
                                    <th> <div className="text-right">{t("evolution.baseYear")} <span >({calculationQuesitonEvolutions?.data?.baseYear})</span></div></th>
                                    <th> <div className="text-right">{t("evolution.lastYear")} <span >({calculationQuesitonEvolutions?.data?.lastYear})</span></div></th>
                                    <th> <div className="text-right">{calculationQuesitonEvolutions?.data?.lastYear} vs {calculationQuesitonEvolutions?.data?.baseYear} kgCO<sub>2</sub> </div></th>
                                    <th> <div className="text-right">{calculationQuesitonEvolutions?.data?.lastYear} vs {calculationQuesitonEvolutions?.data?.baseYear} %</div></th>
                                    <th> <div className="text-right">{t("evolution.currentYear")} <span >({calculationQuesitonEvolutions?.data?.currentYear})</span></div></th>
                                    <th> <div className="text-right">{calculationQuesitonEvolutions?.data?.currentYear} vs {calculationQuesitonEvolutions?.data?.baseYear} kgCO<sub>2</sub></div></th>
                                    <th> <div className="text-right">{calculationQuesitonEvolutions?.data?.currentYear} vs {calculationQuesitonEvolutions?.data?.baseYear} %</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {alcanceActivo === 1 &&
                                    calculationQuesitonEvolutions?.groupedByBuilding[selectedBuilding]?.scope1and2?.map((item) =>
                                        <FilaTabla
                                            key={item.questionId}
                                            item={item}
                                            scope={alcanceActivo}
                                            baseYear={calculationQuesitonEvolutions?.data?.baseYear}
                                            currentYear={calculationQuesitonEvolutions?.data?.currentYear}
                                        />)
                                }
                                {alcanceActivo === 3 &&
                                    calculationQuesitonEvolutions?.groupedByBuilding[selectedBuilding]?.scope3?.map((item) =>
                                        <FilaTabla
                                            key={item.questionId}
                                            item={item}
                                            scope={alcanceActivo}
                                            baseYear={calculationQuesitonEvolutions?.data?.baseYear}
                                            currentYear={calculationQuesitonEvolutions?.data?.currentYear}
                                        />)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>}
        </React.Fragment>
    );


}

function FilaTabla({ item, scope, baseYear, currentYear }) {
    const [t, i18n] = useTranslation("global");
    let lastVsBase = null;
    let lastVsBasePercent = null;
    if (item.lastKgCO2e && item.baseKgCO2e) {
        lastVsBase = item.lastKgCO2e - item.baseKgCO2e;
        lastVsBasePercent = ((item.lastKgCO2e - item.baseKgCO2e) / item.baseKgCO2e) * 100;
    }

    let currentVsBase = null;
    let currentVsBasePercent = null;

    if (item.currentKgCO2e && item.baseKgCO2e) {
        currentVsBase = item.currentKgCO2e - item.baseKgCO2e;
        currentVsBasePercent = ((item.currentKgCO2e - item.baseKgCO2e) / item.baseKgCO2e) * 100;
    }

    const [showExtraTable, setShowExtraTable] = React.useState(false);

    return <><tr key={item.questionId} style={{ backgroundColor: showExtraTable ? "var(--airco2-neutro-color)" : "white" }}>
        <td className="text-left" onClick={() => setShowExtraTable(!showExtraTable)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                className={`transform transition-transform duration-300 ${showExtraTable ? "rotate-180" : ""}`}
                fill="none" stroke="var(--airco2-green-color)" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="2">
                <path d="M6 10l6 6l6 -6h-12"></path>
            </svg>
        </td>
        <td className="text-left">{item.questionTitle} {scope !== 3 && <span className="ml-2 text-xs text-airco2-general"> {t("dash.alcance")} {item.scope}</span>}</td>
        <td className="text-right">{item.baseKgCO2e ? <> {formatThousands(item.baseKgCO2e)}<span className="text-xs ml-1">kgCO<sub>2</sub></span></> : "-"} </td>
        <td className="text-right">{item.lastKgCO2e ? <>{formatThousands(item.lastKgCO2e)}<span className="text-xs ml-1">kgCO<sub>2</sub></span></> : "-"} </td>
        <td className="text-right">{lastVsBase ? <span className={`${lastVsBase < 0 ? "down" : "up"}`}>{formatThousands(lastVsBase)}<span className="text-xs ml-1">kgCO<sub>2</sub></span></span> : "-"} </td>
        <td className="text-right ">{lastVsBasePercent ? <span className={`${lastVsBasePercent < 0 ? "down" : "up"}`}>{formatThousands(lastVsBasePercent)}<span className="text-xs ml-1">%</span></span> : "-"} </td>
        <td className="text-right ">{item.currentKgCO2e ? <>{formatThousands(item.currentKgCO2e)}<span className="text-xs ml-1">kgCO<sub>2</sub></span></> : "-"} </td>
        <td className="text-right">{currentVsBase ? <span className={`${currentVsBase < 0 ? "down" : "up"}`}>{formatThousands(currentVsBase)}<span className="text-xs ml-1">kgCO<sub>2</sub></span></span> : "-"} </td>
        <td className="text-right">{currentVsBasePercent ? <span className={`${currentVsBasePercent < 0 ? "down" : "up"}`}>{formatThousands(currentVsBasePercent)}<span className="text-xs ml-1">%</span></span> : "-"} </td>
    </tr>
        {showExtraTable && (
            <tr style={{ backgroundColor: showExtraTable ? "var(--airco2-neutro-color)" : "white" }}>
                <td colSpan="9">
                    <div className="container-simple-table">
                        <table className="simple-table-sm">
                            <thead>
                                <tr>
                                    <th> <div className="text-left">{t("evolution.activitydata")}</div></th>
                                    <th colSpan={2}> <div className="text-right">{t("evolution.baseYearQuantity")} <br /><span >({baseYear})</span></div></th>
                                    <th> <div className="text-right">{t("evolution.baseYearEmissions")}<br /><span >({baseYear})</span></div></th>
                                    <th colSpan={2}> <div className="text-right">{t("evolution.currentYearQuantity")}  <br /><span >({currentYear})</span></div></th>
                                    <th> <div className="text-right">{t("evolution.currentYearEmissions")}  <br /><span >({currentYear})</span></div></th>
                                    <th colSpan={2}> <div className="text-right">{currentYear} vs {baseYear} <br /><span>{t("evolution.quantity")}</span> </div></th>
                                    <th> <div className="text-right">{currentYear} vs {baseYear} <br /><span>kgCO<sub>2</sub></span> </div></th>
                                    <th> <div className="text-right">{currentYear} vs {baseYear} <br /><span>%</span></div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {item.calculationQuestionEvolutionItemActivityDataDtos.map((itemActivity, index) =>
                                    <FilaTablaSecudaria
                                        key={item.questionId + "-" + index}
                                        item={itemActivity}
                                        questionId={item.questionId} />)}
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
        )}
    </>
}


function FilaTablaSecudaria({ item, questionId }) {
    let currentVsBase = null;
    let currentVsBasePercent = null;
    let currentVsBaseQuantity = null;
    let currentVsBaseQuantity2 = undefined;

    let unit = getQuestionDataUnit(questionId) || getUnitFromName(item.name);
    let unit2 = getQuestion2DataUnit(questionId);

    function getUnitFromName(text) {
        const matches = text.match(/\(([^()]+)\)\s*/g);
        return matches ? matches[matches.length - 1].slice(1, -1) : null;

    }
    if (item.currentKgCO2e && item.baseKgCO2e) {
        currentVsBaseQuantity = item.currentQuantity - item.baseQuantity
        currentVsBasePercent = ((item.currentKgCO2e - item.baseKgCO2e) / item.baseKgCO2e) * 100;
    }
    if (item.baseQuantity2 && item.currentQuantity2) {
        currentVsBaseQuantity2 = item.currentQuantity2 - item.baseQuantity2;
    }



    return <tr key={item.name}>
        <td className="text-left">{item.name}</td>
        <td className="text-right" colSpan={item.baseQuantity && item.baseQuantity2 > 0 ? "1" : "2"}>{item.baseQuantity ? <>{formatThousands(item.baseQuantity)} <span className="text-xs ml-1">{unit}</span></> : "-"} </td>
        <td className={`text-right ${(item.baseQuantity2 === undefined || item.baseQuantity2 === 0) ? "hidden" : ""}`}>{item.baseQuantity2 ? <>{formatThousands(item.baseQuantity2)} <span className="text-xs ml-1">{unit2}</span></> : "-"} </td>
        <td className="text-right">{item.baseKgCO2e ? <>{formatThousands(item.baseKgCO2e)}<span className="text-xs ml-1">kgCO<sub>2</sub></span></> : "-"} </td>


        <td className="text-right" colSpan={item.currentQuantity && item.currentQuantity2 > 0 ? "1" : "2"}>{item.currentQuantity ? <> {formatThousands(item.currentQuantity)} <span className="text-xs ml-1">{unit}</span></> : "-"} </td>
        <td className={`text-right ${(item.currentQuantity2 === undefined || item.currentQuantity2 === 0) ? "hidden" : ""}`}>{item.currentQuantity2 ? <>{formatThousands(item.currentQuantity2)} <span className="text-xs ml-1">{unit2}</span></> : "-"} </td>
        <td className="text-right">{item.currentKgCO2e ? <>{formatThousands(item.currentKgCO2e)}<span className="text-xs ml-1">kgCO<sub>2</sub></span></> : "-"} </td>

        <td className="text-right" colSpan={currentVsBaseQuantity2 ? "1" : "2"}>{currentVsBaseQuantity ? <span className={`${currentVsBaseQuantity < 0 ? "down" : "up"}`}>{formatThousands(currentVsBaseQuantity)}<span className="text-xs ml-1">{unit}</span></span> : "-"} </td>
        <td className={`text-right ${(currentVsBaseQuantity2 === undefined || currentVsBaseQuantity2 === 0) ? "hidden" : ""}`}>{currentVsBaseQuantity2 ? <span className={`${currentVsBaseQuantity2 < 0 ? "down" : "up"}`}>{formatThousands(currentVsBaseQuantity2)} <span className="text-xs ml-1">{unit2}</span></span> : "-"} </td>

        <td className="text-right">{currentVsBase ? <span className={`${currentVsBase < 0 ? "down" : "up"}`}>{formatThousands(currentVsBase)}<span className="text-xs ml-1">kgCO<sub>2</sub></span></span> : "-"} </td>
        <td className="text-right">{currentVsBasePercent ? <span className={`${currentVsBasePercent < 0 ? "down" : "up"}`}>{formatThousands(currentVsBasePercent)}<span className="text-xs ml-1">%</span></span> : "-"} </td>

    </tr>
}