
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


export default function CSRDInstructionsMenu({instructionsPanelOpen, setInstructionsPanelOpen, desktop, isFetching, description, instructions}) {
        const [t, i18n] = useTranslation("global");
    return (<>
    
        {!instructionsPanelOpen.open && desktop &&
            <span className='italic text-xs flex items-center ' id='footprint-description-section'
                onClick={() => setInstructionsPanelOpen({ open: !instructionsPanelOpen.open, fixed: true })}>
                <button onClick={() => setInstructionsPanelOpen({ open: !instructionsPanelOpen.open, fixed: true })}
                    onMouseEnter={() => !instructionsPanelOpen.open && setInstructionsPanelOpen({ open: !instructionsPanelOpen.open, fixed: false })}
                    className="flex flex-row items-center"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-left"
                        width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M11 7l-5 5l5 5" />
                        <path d="M17 7l-5 5l5 5" />
                    </svg>
                    <p className="text-xs pl-1" style={{ writingMode: "vertical-rl", transform: "rotate(180deg)", whiteSpace: "nowrap" }}>
                        {t("footPrint.descripcionInstrucciones")}
                    </p>

                </button>
            </span>
        }

        {instructionsPanelOpen.open &&
            <div className='p-2 border sidebar-airco2 relative' style={{ width: desktop ? '15%' : '0%', height: desktop ? '100%' : '', overflowY: desktop ? 'scroll' : 'hidden' }}
                onMouseLeave={() => { instructionsPanelOpen.open && !instructionsPanelOpen.fixed && setInstructionsPanelOpen({ open: !instructionsPanelOpen.open, fixed: false }) }}
                onClick={() => { instructionsPanelOpen.open && !instructionsPanelOpen.fixed && setInstructionsPanelOpen({ open: true, fixed: true }) }}>

                <div className='italic text-xs flex items-center justify-end mr-2 fixed z-10 right-6' id='footprint-description-section'>
                    <button onClick={() => setInstructionsPanelOpen({ open: !instructionsPanelOpen.open, fixed: true })} >
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-right"
                            width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M7 7l5 5l-5 5" />
                            <path d="M13 7l5 5l-5 5" />
                        </svg>
                    </button>
                </div>

                <div className='p-2'>
                    <p className='font-bold'>{t('footPrint.descripcion')}</p>
                    {isFetching ?
                        <span className='text-sm italic' dangerouslySetInnerHTML={{ __html: description }} />
                        :
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true" />}
                </div>
                <div className='p-2'>
                    <p className='font-bold'>{t('footPrint.instrucciones')}</p>
                    {isFetching ?
                        <span className='text-sm italic' dangerouslySetInnerHTML={{ __html: instructions }} />
                        :
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true" />}
                </div>
            </div>


        }</>)
}