import React from "react";
import { useTranslation } from 'react-i18next';
import ciclo from '../../../images/gp_ciclo.png';
import cert1 from '../../../images/gp-BLUEENGEL_GPaz.png';
import cert3 from '../../../images/gp-carbon-footprint-calculated-1.png';
import cert4 from '../../../images/gp-carbon-footprint-calculated-2.png';
import cert2 from '../../../images/gp-applus-iso.png';
import imgAllBags from '../../../images/img-bolsas-great-packaging.svg';
import calculoHuella from '../../../images/calculohuellaCencosud.png';

import ImageSlider from "./imageSlider";
import TableGreatPackaging from "./table";
import TableGreatPackagingResposive from "./responsiveTable";

  
export default function landingGreatPackaging() {
  const [t, i18n] = useTranslation("global");
  const cert = process.env.REACT_APP_URL + '/pdf/applus_certificado_gp.pdf';

  return (

    <main className="font-gp pt-4 container-great-packaging xl:mx-8">

      <div className="container">

        <div className="py-5">
          <div className="row  flex  gp-container-bolsas">
            <div className="col-12 col-md-6 flex " data-aos="fade-up-right">
              <div className="flex items-center justify-center h-auto max-w-full w-full">
                <ImageSlider className="gp-image" />
              </div>
            </div>
            <div className="col-12 col-md-6 flex flex-col  justify-center" data-aos="fade-up-left">
              <h2 className="text-3xl  mb-4 text-gp-green uppercase text-center">
                {t("gp.block1.title")}
              </h2>
              <div className="text-justify  text-xl-gp text-gp-lightgrey">
                <p className="mb-3">
                  {t("gp.block1.desc1")}
                </p>
                <p className="mb-3">
                  {t("gp.block1.desc2")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5">
          <h2 className="text-3xl  mb-4 text-gp-green text-center" data-aos="fade-up-top">
            {t("gp.block2.title")}
          </h2>
          <div className="row align-items-center">
            <div className="col-12 col-md-6" data-aos="fade-up-right">
              <div className="text-justify  text-xl-gp text-gp-lightgrey">
                <p>
                  {t("gp.block2.desc1")}
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6" data-aos="fade-up-left">
              <div className="flex items-center justify-center">
                <img src={ciclo} className="gp-image" width="230px"></img>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5">
          <h2 className="text-3xl  mb-4 text-gp-green text-center" data-aos="fade-up-top">
            {t("gp.block6.title")}
          </h2>
          <div className="row align-items-center">
            <div className="col-12 col-md-6 hidden md:block" data-aos="fade-up-left">
              <div className="flex items-center justify-center">
                <img src={calculoHuella} className="gp-image-calculo-huella" width="330px"></img>
              </div>
            </div>
            <div className="col-12 col-md-6" data-aos="fade-up-right">
              <div className="text-justify  text-xl-gp text-gp-lightgrey">
                <p>
                  {t("gp.block6.desc1")}
                  <br/>
                  {t("gp.block6.desc2")}
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 visible md:hidden" data-aos="fade-up-left">
              <div className="flex items-center justify-center">
                <img src={calculoHuella} className="gp-image-calculo-huella" width="300px"></img>
              </div>
            </div>
          </div>
        </div>


        <div className="py-5">
          <h2
            className="text-3xl  mb-4 text-gp-green  text-center  " data-aos="fade-up-top">
            {t("gp.block3.title")}
          </h2>
          <div className="text-justify  text-xl-gp text-gp-lightgrey" data-aos="zoom-in">
            <p className="mb-3">
              {t("gp.block3.desc1")}
            </p>
            <p className="mb-3">
              {t("gp.block3.desc2")}
            </p>
            <p className="mb-3">
              {t("gp.block3.desc3")}
            </p>
          </div>

          <div className="flex flex-col justify-center h-100 mx-2 md:m-0 " data-aos="zoom-in">
            <img
              src={imgAllBags}
              alt="img-bags"
            />
          </div>

          <div className=" m-2 md:m-0 " id="tableBottom" data-aos="zoom-in">
            <div className="hidden md:block">
              <TableGreatPackaging />
            </div>
            <div className="block md:hidden">
              <TableGreatPackagingResposive />
            </div>
            <p className="text-md text-justify mb-3">
              {t("gp.block4.desc1")} <a href={cert} target="_blank">{t("gp.block4.desc1a")}</a>.</p>
            <p className="text-md text-justify mb-3">
              {t("gp.block4.desc2")} </p>
            <p className="text-md text-justify mb-3">
              {t("gp.block4.desc3")} </p>
          </div>
        </div>


        <div className="py-5 ">
          <div className="certification-container" data-aos="fade-up-top">
            <h2 className="certification-heading">{t("gp.block5.title")}</h2>
          </div>
          <div className="flex items-center justify-center md:gap-4">
            <div data-aos="flip-left" className="gp-image-cert"> <img src={cert1} alt="Blauner Engel"  ></img></div>
            <div data-aos="flip-left" className="gp-image-cert"><img src={cert2} alt="Applus"  ></img></div>
            <div data-aos="flip-left" className="gp-image-cert"><img src={cert3} alt="Recycled UNE"  ></img></div>
            <div data-aos="flip-left" className="gp-image-cert"><img src={cert4} alt="Recycled UNE"  ></img></div>
          </div>
        </div>

        <div className="py-5" style={{ marginTop: '150px' }}></div>

      </div >
    </main >
  );
}
