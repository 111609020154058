export default function CSRDContent({ questionsPanelOpen, instructionsPanelOpen, desktop }) {
    return (
        <div className="bg-white sidebar-airco2"
            style={{
                width: !desktop ? '100%' : instructionsPanelOpen.open && questionsPanelOpen.open ? '70%' : (instructionsPanelOpen.open && !questionsPanelOpen.open ? '85%' : (!instructionsPanelOpen.open && questionsPanelOpen.open ? "85%" : "100%")),
                height: desktop ? '100%' : '',
                overflowY: desktop ? 'scroll' : 'hidden'
            }
            } >
            <div style={{ borderTop: '1px solid #dee2e6', borderBottom: '1px solid #dee2e6' }}>
            </div>
        </div >)
}