import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../../components/utils/Banner";
import UserService from "../../../services/UserService";
import { formatDateTime } from "../utils/Utils";
import { IdiomContext } from "./../../../context/createcontext";

function AccountPanel() {
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");
  const [sync, setSync] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [dni, setDni] = useState("");
  const [titularCuenta, setTitularCuenta] = useState("");
  const [dpostal, setDpostal] = useState("");
  const [cp, setCP] = useState("");
  const [bankingEntity, setBankingEntity] = useState("");
  const [iban, setIban] = useState("");

  useEffect(() => {
    UserService.userProfile().then((result) => {
   
      setUserProfile(result);
      setCompanyName(result.attributes.companyName);
      setPhone(result.attributes.phone);
      setName(result.firstName);
      setLastName(result.lastName)
      setDni(result.attributes.dni);
      setTitularCuenta(result.attributes.titularCuenta);
      setDpostal(result.attributes.dpostal);
      setSelectedMunicipio(result.attributes.municipioCode);
      setCP(result.attributes.codigoPostal);
      setBankingEntity(result.attributes.bankingEntity);
      setIban(result.attributes.iban);
    });
  }, [updateKeycloakUSerProfile]);


  const [disable, setDisable] = React.useState(true);
  const [disableButtonFrom, setDisableButtonFrom] = React.useState(false);

  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState({
    password: "",
    confirmPassword: "",
  });

  const [formPasswordMessage, setFormPasswordMessage] = useState({
    type: "",
    message: "",
  });

  const [formMessage, setFormMessage] = useState({
    type: "",
    message: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    setFormPasswordMessage({ ...formPasswordMessage, type: "", message: "" });

    let { name, value } = e.target;
    setPasswordError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = t("account.introduce-pass");
            setDisable(true);
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] = t("account.pass-no-coincide");
            setDisable(true);
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : passwordError.confirmPassword;
            setDisable(true);
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = t("account.pass-confirma");
            setDisable(true);
          } else if (input.password && value !== input.password) {
            stateObj[name] = t("account.pass-no-coincide");
            setDisable(true);
          } else {
            setNewPassword(input.password);
            setDisable(false);
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const onFormInputChange = (e) => {
    let { name, value } = e.target;
    if (value != "") {
      setFormMessage({ ...formMessage, type: "", message: "" });
    }
  };

  const reinizaliteInputs = (e) => {
    setPhone(UserService.getPhone() == undefined ? "" : UserService.getPhone());
    setCompanyName(
      UserService.getCompanyName() == undefined
        ? ""
        : UserService.getCompanyName()
    );

    setName(UserService.getName() == undefined ? "" : UserService.getName());
    setLastName(UserService.getLastName() == undefined ? "" : UserService.getLastName());
    setDni(UserService.getDni() == undefined ? "" : UserService.getDni());
    setTitularCuenta(
      UserService.getTitularCuenta() == undefined
        ? ""
        : UserService.getTitularCuenta()
    );
    setDpostal(
      UserService.getDpostal() == undefined ? "" : UserService.getDpostal()
    );
    setBankingEntity(
      UserService.getBankingEntity() == undefined
        ? ""
        : UserService.getBankingEntity()
    );
    setIban(UserService.getIban() == undefined ? "" : UserService.getIban());
    setSelectedMunicipio(
      UserService.getMunicipio() == undefined ? "" : UserService.getMunicipio()
    );
    setCP(
      UserService.getCodigoPostal() == undefined
        ? ""
        : UserService.getCodigoPostal()
    );
  };

  const updateKeycloakUSerProfile = (e) => {
    e.preventDefault();
    setDisableButtonFrom(true);
    setFormMessage({ ...formMessage, type: "", message: "" });

    if (
      UserService.getUserType() == "Representante empresarial" &&
      (
        companyName == "" ||
        name == "" ||
        name == "" ||
        phone == "" ||
        dni == "" ||
        lastName == "")
    ) {
      setDisableButtonFrom(false);
      setFormMessage({
        ...formMessage,
        type: "warning",
        message: t("account.form-aviso-cubre-campos"),
      });
      return;
    } else if (
      UserService.getUserType() != "Representante empresarial" &&
      (phone == "" ||
        name == "" ||
        lastName == "" ||
        dni == "" ||
        titularCuenta == "" ||
        dpostal == "" ||
        bankingEntity == "" ||
        iban == "" ||
        cp == "" ||
        selectdeMunicipio == "" ||
        phone == undefined ||
        name == undefined ||
        dni == undefined ||
        titularCuenta == undefined ||
        dpostal == undefined ||
        bankingEntity == undefined ||
        iban == undefined ||
        cp == undefined ||
        selectdeMunicipio == undefined)
    ) {
      setDisableButtonFrom(false);
      setFormMessage({
        ...formMessage,
        type: "warning",
        message: t("account.form-aviso-cubre-campos"),
      });
      return;
    }
    UserService.updateProfile(
      name,
      lastName,
      phone,
      companyName,
      dni,
      titularCuenta,
      dpostal,
      bankingEntity,
      iban,
      cp,
      selectdeMunicipio
    ).then((result) => {
   
      setDisableButtonFrom(false);
      if (result) {
        setFormMessage({
          ...formMessage,
          type: "success",
          message: t("account.actualizacion-ok"),
        });
      } else {
        setFormMessage({
          ...formMessage,
          type: "error",
          message: t("account.actualizacion-ko"),
        });
      }
    });
  };

  const updatePassword = () => {
    UserService.resetPassword(newPassword).then((result) => {
      if (result) {
        setFormPasswordMessage({
          ...formPasswordMessage,
          type: "success",
          message: t("account.actualizacion-ok"),
        });
      } else {
        setFormPasswordMessage({
          ...formPasswordMessage,
          type: "error",
          message: t("account.actualizacion-ko"),
        });
      }
    });
  };

  const [locations, setLocations] = useState({
    locationsData: [],
    isFetching: false,
  });
  //Fetch locations MP
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        setLocations({
          locationsData: locations.locationsData,
          isFetching: true,
        });
        await axios
          .get(`${process.env.REACT_APP_MP_LOCATIONS}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          })
          .then((response) => {
            setLocations({ locationsData: response.data, isFetching: false });
          });
      } catch (exception) {
        setLocations({
          locationsData: locations.locationsData,
          isFetching: false,
        });
      }
    };
    fetchLocations();
  }, []);

  const [provincias, setProvincias] = useState(null);
  const [selectedProvincia, setSelectedProvincia] = useState("");
  const [selectdeMunicipio, setSelectedMunicipio] = useState("");

  useEffect(() => {
    if (locations.locationsData[0] != undefined) {
      let listProvicias = [];
      for (
        let index = 0;
        index < locations.locationsData[0].regionList.length;
        index++
      ) {
        let actual = locations.locationsData[0].regionList[index];
        actual.stateList.map((v) => {
          const cities = v.citiesList;
          const municipio = cities.filter((x) => x.code == selectdeMunicipio);
          if (municipio.length > 0) {
            setSelectedProvincia(v.id);
          }
          listProvicias.push({ name: v.name, value: v.id });
        });
      }
      setProvincias(listProvicias.sort((x, y) => x.name.localeCompare(y.name)));
    }
  }, [locations.locationsData]);

  const [municipios, setMunicipios] = useState(null);
  useEffect(() => {
    if (locations.locationsData[0] != undefined) {
      for (
        let index = 0;
        index < locations.locationsData[0].regionList.length;
        index++
      ) {
        let actual = locations.locationsData[0].regionList[index];
        actual.stateList.map((v) => {
          if (v.id == selectedProvincia) {
            setMunicipios(
              v.citiesList.sort((x, y) => x.name.localeCompare(y.name))
            );
          }
        });
      }
    }
  }, [selectedProvincia]);

  const [currentPlan, setCurrentPlan] = useState({
    data: undefined,
    isFetching: false,
  });

  useEffect(() => {
    const fetchUserPlan = async () => {
      const url = `${process.env.REACT_APP_CH_PLAN}`;

      try {
        const response = await axios.get(`${url}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${UserService.getToken()}`,
          },
        });

        setCurrentPlan({ data: response?.data?.data, isFetching: true });
      } catch (exception) {
        setCurrentPlan({ data: undefined, isFetching: true });
        console.error(exception);
      }
    };
    fetchUserPlan();
  }, []);


  return (
    <div className="grow sec-neutro-background">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-gray-800 font-bold mb-2">
          {t("account.mi-cuenta")}
        </h2>

        {(currentPlan.data != undefined && UserService.getUserType() === "Representante empresarial") && (
          <div className="mb-4 p-4 sec-green-background text-center text-white">
            <b> {currentPlan.data.planName}</b>{" "}
            {currentPlan.data.expirationDate != undefined && (
              <>
                {t("footPrint.validoHasta")}
                <b>{formatDateTime(currentPlan.data.expirationDate)}</b>.
              </>
            )}
          </div>
        )}

        {/* Business Profile: Representante empresarial VS Monte */}
        <section>
          {UserService.getUserType() == "Representante empresarial" ? (
            <>
              <h2 className="text-xl leading-snug text-gray-800 font-bold mb-1">
                {" "}
                {t("account.perfil-empresa")}{" "}
              </h2>
            </>
          ) : (
            <>
              <h2 className="text-xl leading-snug text-gray-800 font-bold mb-1"></h2>
            </>
          )}

          <div className="text-base font-semibold">
            {t("account.form-title")}{" "}
          </div>
          {formMessage.type != "" && (
            <Banner type={formMessage.type} open setOpen={true}>
              {formMessage.message}
            </Banner>
          )}

          <form onSubmit={updateKeycloakUSerProfile}>
            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-4">
              <div className="sm:w-1/3">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="name"
                >
                  {t("account.form-nombre")}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  id="name"
                  className="form-input w-full"
                  type="text"
                  required
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  onKeyUp={onFormInputChange}
                />
              </div>

              <div className="sm:w-1/3">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="lastname"
                >
                  {t("account.form-lastname")}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  id="lastname"
                  className="form-input w-full"
                  type="text"
                  required
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  onKeyUp={onFormInputChange}
                />
              </div>
              {UserService.getUserType() == "Representante empresarial" ? (
                <>
                  <div className="sm:w-1/3">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="companyName"
                    >
                      {t("account.form-empresa")}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="companyName"
                      className="form-input w-full"
                      type="text"
                      required
                      value={companyName}
                      onChange={(event) => setCompanyName(event.target.value)}
                      onKeyUp={onFormInputChange}
                    />
                  </div>

                </>
              ) : (
                <></>
              )}
              <div className="sm:w-1/3">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="phone"
                >
                  {t("account.form-telefono")}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  id="phone"
                  className="form-input w-full"
                  type="text"
                  required
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                  onKeyUp={onFormInputChange}
                />
              </div>
              <div className="sm:w-1/3">
                <label className="block text-sm font-medium mb-1" htmlFor="dni">
                  {t("account.dni")}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  id="dni"
                  className="form-input w-full"
                  type="text"
                  required
                  value={dni}
                  onChange={(event) => setDni(event.target.value)}
                  onKeyUp={onFormInputChange}
                />
              </div>
            </div>

            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-3 mb-4">
              <div className="sm:w-1/2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="dpostal"
                >
                  {t("account.dirPostal")}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  id="dpostal"
                  className="form-input w-full"
                  type="text"
                  required
                  value={dpostal}
                  onChange={(event) => setDpostal(event.target.value)}
                  onKeyUp={onFormInputChange}
                />
              </div>

              <div className="sm:w-1/4">
                <label className="block text-sm font-medium mb-1" htmlFor="cp">
                  {t("account.cp")}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  id="cp"
                  className="form-input w-full"
                  type="text"
                  required
                  value={cp}
                  onChange={(event) => setCP(event.target.value)}
                  onKeyUp={onFormInputChange}
                />
              </div>
              <div className="sm:w-1/3">
                <label className="block text-sm font-medium mb-1">
                  {t("marketplace.form_provincia")}
                  <span className="text-red-500">*</span>
                </label>
                <select
                  id="provincias"
                  name=""
                  className="form-input w-full"
                  type="text"
                  required
                  value={selectedProvincia}
                  onChange={(e) => setSelectedProvincia(e.target.value)}
                >
                  {provincias == null && (
                    <option name="provincias" className="w-full" value="">
                      {t("sidebar.cargando")}...
                    </option>
                  )}
                  {provincias != null && (
                    <option name="provincias" className="w-full" value="">
                      {t("marketplace.form_select")}
                    </option>
                  )}
                  {provincias &&
                    provincias.map((v) => {
                      return (
                        <option
                          key={v.value + "provincias"}
                          name={v.name}
                          className="w-full"
                          value={v.value}
                        >
                          {v.name}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="sm:w-1/3">
                <label className="block text-sm font-medium mb-1">
                  {t("marketplace.form_city")}
                  <span className="text-red-500">*</span>
                </label>
                <select
                  id="municipio"
                  name=""
                  className="form-input w-full"
                  type="text"
                  required
                  value={selectdeMunicipio}
                  onChange={(e) => setSelectedMunicipio(e.target.value)}
                >
                  {municipios == null && (
                    <option name="municipio" className="w-full" value="">
                      {t("sidebar.cargando")}...
                    </option>
                  )}
                  {municipios != null && (
                    <option name="municipio" className="w-full" value="">
                      {t("marketplace.form_select")}
                    </option>
                  )}
                  {municipios &&
                    municipios.map((v) => {
                      return (
                        <option
                          key={v.id + "municipio"}
                          name={v.name}
                          className="w-full"
                          value={v.code}
                        >
                          {v.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            {/* Datso bancarios */}
            {UserService.getUserType() == "Propietario Forestal" ? (
              <section className="mt-4 pt-4">
                <div className="text-base font-semibold">
                  {t("account.datos_bancarios")}
                </div>

                <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-3 mb-4">
                  <div className="sm:w-1/3">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="titularCuenta"
                    >
                      {t("account.titular")}
                    </label>
                    <input
                      id="titularCuenta"
                      className="form-input w-full"
                      type="text"
                      required
                      value={titularCuenta}
                      onChange={(event) => setTitularCuenta(event.target.value)}
                      onKeyUp={onFormInputChange}
                    />
                  </div>
                  <div className="sm:w-1/4">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="bankingEntity"
                    >
                      {t("account.entidad_bancaria")}
                    </label>
                    <input
                      id="bankingEntity"
                      className="form-input w-full"
                      type="text"
                      required
                      value={bankingEntity}
                      onChange={(event) => setBankingEntity(event.target.value)}
                      onKeyUp={onFormInputChange}
                    />
                  </div>
                  <div className="sm:w-1/2 ">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="iban"
                    >
                      {t("account.iban")}
                    </label>
                    <input
                      id="iban"
                      className="form-input w-full"
                      type="text"
                      required
                      value={iban}
                      onChange={(event) => setIban(event.target.value)}
                      onKeyUp={onFormInputChange}
                    />
                  </div>
                </div>
              </section>
            ) : (
              <></>
            )}

            <div className="flex flex-col pt-4">
              <div className="flex self-end">
                <button
                  arial-label="btn-reinizalite"
                  className="btn border-gray-200 hover:border-gray-300 text-gray-600"
                  onClick={reinizaliteInputs}
                >
                  {t("account.form-cancelar")}
                </button>
                <button
                  arial-label="btn-saveForm"
                  className="btn btn-green ml-3"
                  type="submit"
                  disabled={disableButtonFrom}
                >
                  {t("account.form-guardar")}
                </button>
              </div>
            </div>
          </form>
        </section>

        {/* Password */}
        <section>
          <h2 className="text-xl leading-snug text-gray-800 font-bold">
            {t("account.form-pass-title")}
          </h2>
          <div className="text-sm">{t("account.form-pass-subtitle")}</div>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5 mb-4">
            <div className="sm:w-1/3">
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="business-id"
              >
                {t("account.form-pass")}
              </label>
              <input
                id="password"
                className="form-input w-full"
                name="password"
                type="password"
                value={input.password}
                onChange={onInputChange}
                onBlur={validateInput}
              />
            </div>
            <div className="sm:w-1/3">
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="location"
              >
                {t("account.form-pass-repite")}
              </label>
              <input
                id="seconfirmPasswordctor"
                className="form-input w-full"
                name="confirmPassword"
                type="password"
                value={input.confirmPassword}
                onChange={onInputChange}
                onBlur={validateInput}
              />
            </div>
          </div>
          {passwordError.confirmPassword && (
            <Banner type="warning" open setOpen={true}>
              {passwordError.confirmPassword}
            </Banner>
          )}
          {formPasswordMessage.type != "" && (
            <Banner type={formPasswordMessage.type} open setOpen={true}>
              {formPasswordMessage.message}
            </Banner>
          )}

          <div className="flex flex-col pt-4 ">
            <div className="flex self-end">
              <button
                arial-label="btn-updatePass"
                className="btn btn-green ml-3"
                disabled={disable}
                onClick={updatePassword}
              >
                {t("account.form-pass-save")}
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AccountPanel;
