import coolberries from "../images/cool_farming_sinfondo_horiz.png";
import abanca from "../images/logo-e757d274.svg";
import kutxabank from "../images/pngegg.png";
import aplanet from "../images/APLANET_bgWhite-1.webp";
import xuntasicle from "../images/xunta-sicle.png";

export const whiteLabelData = [
  {
    id: "coolberries",
    name: "Cool Farming",
    color: "#EA5A0B",
    logo: coolberries,
    url: "https://coolfarming.bio/",
    secodarycolor: "#222D70",
    text: "text-coolberries-general",
    font: "Montserrat",
    colorBg: "#F7F8FB",
    colorBgSidebar: "white",
    urlLogin: "/login-external",
    urlLogout: "https://coolfarming.bio/",
  },
  {
    id: "abanca",
    name: "Abanca",
    color: "#5b87da",
    logo: abanca,
    url: "https://coolfarming.bio/",
    secodarycolor: "#d0ddf8",
    text: "text-coolberries-general",
    font: "Montserrat",
    colorBg: "#F7F8FB",
    colorBgSidebar: "white",
    urlLogin: "/login-external",
    urlLogout: "https://coolfarming.bio/",
  },
  {
    id: "kutxabank",
    name: "Kutxabank",
    color: "#000000",
    logo: kutxabank,
    url: "https://coolfarming.bio/",
    secodarycolor: "#ED2F24",
    text: "text-coolberries-general",
    font: "Montserrat",
    colorBg: "#FFFFFF",
    colorBgSidebar: "white",
    urlLogin: "/login-external",
    urlLogout: "https://coolfarming.bio/",
  },
  {
    id: "aplanet",
    name: "APLANET",
    color: "#000000",
    logo: aplanet,
    url: "https://coolfarming.bio/",
    secodarycolor: "#9d62ff",
    text: "text-coolberries-general",
    font: "Montserrat",
    colorBg: "#FFFFFF",
    colorBgSidebar: "white",
    urlLogin: "/login-external",
    urlLogout: "https://coolfarming.bio/",
  },
  {
    id: "xunta-sicle",
    name: "Xunta - Sicle CO2",
    color: "#007bc4",
    logo: xuntasicle,
    url: "https://www.xunta.gal/",
    secodarycolor: "#002f50",
    text: "text-coolberries-general",
    font: "Xunta",
    colorBg: "#FFFFFF",
    colorBgSidebar: "white",
    urlLogin: "/login-external",
    urlLogout: "https://www.xunta.gal/",
  },
];

export const getColor = (whiteLabel) => {
  const data = whiteLabelData.find((x) => x.id === whiteLabel);
  return data ? data.color : "#8DD4BD";
};

export const getName = (whiteLabel) => {
  const data = whiteLabelData.find((x) => x.id === whiteLabel);
  return data ? data.name : "airCO2";
};

export const getSecondaryColor = (whiteLabel) => {
  const data = whiteLabelData.find((x) => x.id === whiteLabel);
  return data ? data.secodarycolor : "#878787";
};

export const getLogo = (whiteLabel) => {
  const data = whiteLabelData.find((x) => x.id === whiteLabel);
  return data ? data.logo : "";
};

export const getUrl = (whiteLabel) => {
  const data = whiteLabelData.find((x) => x.id === whiteLabel);
  return data ? data.url : "";
};

export const getFont = (whiteLabel) => {
  const data = whiteLabelData.find((x) => x.id === whiteLabel);
  return data ? data.text : "text-airco2-general";
};

export const getLogout = (whiteLabel) => {
  const data = whiteLabelData.find((x) => x.id === whiteLabel);
  return data ? data.urlLogout : "/";
};

export const getLogin = (whiteLabel) => {
  const data = whiteLabelData.find((x) => x.id === whiteLabel);
  return data ? data.urlLogin : "/";
};
