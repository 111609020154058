import axios from "axios";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import UserService from "../../../../services/UserService";
import ImportExcelUploadFileOrData from "../ImportExcelUploadFileOrData";
import ModalMatchColumns from "./../columnMappingForm";
import ClientExcelValidateData from "./ClientExcelValidateData";


export default function ClientExcelImport({ updateClients, setModalExcelImport }) {
    const [t, i18n] = useTranslation("global");
    const [excelData, setExcelData] = useState([]);
    const inputs = [
        { label: "cif", title: "CIF", type: "STRING", required: true },
        { label: "legalName", title: "Nombre legal", type: "STRING", required: true },
        { label: "companyName", title: "Nombre de la empresa", type: "STRING", required: true },
        { label: "identifier", title: "Identificador", type: "STRING", required: true },
        { label: "email", title: "Correo electrónico", type: "EMAIL", required: true },
        { label: "address", title: "Dirección", type: "STRING", required: true },
        { label: "postalCode", title: "Código postal", type: "NUMBER", required: true },
        { label: "stateId", title: "Provincia", type: "STATE", required: true },
        { label: "organizationIdList", title: "Organización", type: "ORGANIZATIONS", required: true },
    ];

    const [processStateIndex, setProcessStateIndex] = useState(1);
    const [formatData, setFormatData] = useState([]);


    const processStates = [{ index: 1, title: t("clients.excelBreadcums1") },
    { index: 2, title: t("clients.excelBreadcums2") },
    { index: 3, title: t("clients.excelBreadcums3") }];

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);

    const [organizations, setOrganizations] = useState([]);
    const [spinnerDownloadExcel, setSpinnerDownloadExcel] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [organizationsResponse, locationsResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_CH_ORGANIZATIONS}?userId=${UserService.getUserId()}`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${UserService.getToken()}`
                        }
                    }),
                    axios.get(`${process.env.REACT_APP_CH_LOCATIONS}`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${UserService.getToken()}`
                        }
                    })
                ]);

                // Procesar datos de organizaciones
                const orgData = organizationsResponse.data.data
                    .map(org => ({ id: org.id, name: org.name }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                setOrganizations(orgData);

                const countriesList = locationsResponse.data.data.filter(item => item.regionList.length > 0).map(item => ({
                    id: item.id,
                    name: item.name,
                    regionList: item.regionList
                }))

                const sortedCountries = JSON.parse(JSON.stringify(countriesList)).sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });

                setCountries(sortedCountries);

                const statesList = sortedCountries.flatMap(countryItem =>
                    countryItem.regionList.flatMap(regionItem =>
                        regionItem.stateList.map(stateItem => ({
                            id: stateItem.id,
                            name: stateItem.name
                        }))
                    )
                );

                setStates(statesList)

            } catch (error) {
                console.error("Error fetching data:", error);
                setOrganizations([]);
                setCountries([]);
                setStates([]);
            }
        };

        fetchData();
    }, []);

    const handleGetExcelDataFromStep1 = (processedExcelData) => {
        setExcelData(processedExcelData);
        setProcessStateIndex(2);
    }

    const handleColumnMappingSubmit = (mappings) => {
        if (mappings.length > 0) {
            setFormatData(dataFormater(mappings))
            setProcessStateIndex(3);
        } else {
            handleResetExcelComponent();
        }
    };


    const handleResetExcelComponent = () => {
        setExcelData([])
        setProcessStateIndex(1);
    }
    const [totalUploads, setTotalUploads] = useState(0);
    const handleFinishUploadOk = async (totalRowsUploads) => {
        try {
            await new Promise(resolve => {
                updateClients();
                setProcessStateIndex(4);
                setTotalUploads(totalRowsUploads);
                resolve()
            });
        } catch (error) {
            console.error('Error en handleFinishUploadOk', error)
        }
    }

    //Transformar la data de excel 
    const dataFormater = (mappings) => {
        if (excelData.length === 0) {
            return [];
        }
        let formattedData = excelData.slice(1).map((row) => {
            let formattedRow = Array(inputs.length);
            for (let i = 0; i < mappings.length; i++) {
                let obj = mappings[i];
                if (obj && typeof obj.index !== undefined) {
                    formattedRow[i] = { name: row[obj.index], label: inputs[i].label, valid: false };
                } else {
                    formattedRow[i] = { name: "", label: inputs[i].label, valid: false };
                }
            }
            return formattedRow;
        });
        return formattedData;
    };

    const fetchDownloadExcelTemplate = async () => {
        setSpinnerDownloadExcel(true);
        let url = `${process.env.REACT_APP_PY_CREATE_EXCEL_GENERAL}?name=${t("clients.excelTemplateName")}`;

        const dataInputs = inputs.map((input) => {
            if (input.type === "ORGANIZATIONS") {
                return {
                    ...input,
                    options: organizations,
                };
            } else if (input.type === "STATE") {
                return {
                    ...input,
                    options: states,
                };
            }
            return input;
        });

        let data = { "inputs": dataInputs };


        try {
            const response = await axios.post(url, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                responseType: 'blob',  // Especifica que esperas un archivo binario (Blob)
            });

            // Crear una URL temporal para el Blob (archivo)
            const fileURL = window.URL.createObjectURL(response.data);

            // Crear un enlace temporal para activar la descarga
            const a = document.createElement('a');
            a.href = fileURL;
            a.download = `${t("clients.excelTemplateName")}.xlsx`;  // Asigna el nombre del archivo
            document.body.appendChild(a);
            a.click();  // Simula un clic para descargar
            a.remove();  // Elimina el enlace después de la descarga

            // Limpiar la URL del Blob
            window.URL.revokeObjectURL(fileURL);

        } catch (exception) {
            console.error("Error al obtener el archivo:", exception);
            setSpinnerDownloadExcel(false);
        }
        setSpinnerDownloadExcel(false);
    };


    return <div className="flex h-full flex-col">
        <div className=" flex flex-wrap justify-start w-full gap-4 bg-white pt-2 pb-4 z-80" style={{ position: 'fixed', top: '65.5px' }}>
            {
                processStates.map((v, i) => {
                    return (
                        <div className="flex items-center gap-4" key={i}>
                            <div className={` text-lg ${processStateIndex >= v.index ? "font-semibold cursor-pointer" : ""} `}
                                onClick={() => processStateIndex > v.index ? setProcessStateIndex(v.index) : ""}
                            >
                                {v.title}
                            </div>
                            {!(i == processStates.length - 1) &&
                                <div>&gt;</div>}
                        </div>
                    )
                })
            }
        </div>
        <div className="flex flex-col h-full" style={{ marginTop: "50px", marginBottom: "20px" }}>
            {(organizations.length == 0 || countries.length == 0) &&
                <div className="flex flex-col items-center w-full h-full justify-center text-center">
                    <Spinner animation="border" variant="dark" size="lg" />
                    <div><p>{t("clients.chargingExcel")}</p></div>
                </div>}

            {organizations.length > 0 && countries.length > 0 &&
                <>
                    {processStateIndex == 1 && <>
                        <div className='flex justify-end'>
                            <button onClick={() => fetchDownloadExcelTemplate()}
                                className={"btn-sm btn-green text-xs"}
                                disabled={spinnerDownloadExcel}>
                                {spinnerDownloadExcel ?
                                    <Spinner animation="border" variant="light" size="sm" /> :
                                    <> {t("clients.downloadClientExcelTemplate")}</>
                                }
                            </button>
                        </div>
                        <div className="mt-4">
                            <ImportExcelUploadFileOrData
                                excelHeaders={inputs.map(i => i.title)}
                                handleGetExcelData={handleGetExcelDataFromStep1} />
                        </div>
                    </>}

                    {processStateIndex == 2 && <ModalMatchColumns
                        userColumns={excelData[0]}
                        predefinedColumns={inputs.map(i => i.title)}
                        predefinedColumnsRequired={inputs.filter(f => f.required).map(i => i.title)}
                        onMappingSubmit={handleColumnMappingSubmit}
                        userFirstLineData={excelData[1]}
                        terceros={false}
                    />}

                    {processStateIndex == 3 && <ClientExcelValidateData
                        data={formatData}
                        handleResetExcelComponent={handleResetExcelComponent}
                        inputs={inputs}
                        organizationsList={organizations}
                        countriesList={countries}
                        handleFinishUploadOk={handleFinishUploadOk}

                    />}
                    {processStateIndex == 4 && <div className="flex flex-col items-center w-full h-full justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke=" var(--airco2-green-color)" strokeLinecap="round" strokeLinejoin="round" width="85" height="85" strokeWidth="2">
                            <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"></path>
                            <path d="M9 12l2 2l4 -4"></path>
                        </svg>
                        <div className="m-3 text-center">
                            <p className="font-bold text-lg">{t("clients.uploadExcelOk")}</p>
                            <p>{t("clients.totalUploads")} {totalUploads}</p>
                        </div>
                        <div> <button onClick={() => setModalExcelImport(false)} className={"btn-sm btn-green text-xs"} >
                            {t("clients.goback")}
                        </button>
                        </div>
                    </div>}

                </>
            }
        </div>

    </div>
}






