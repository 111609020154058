import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function CSRDQuestionMenu({ questionsPanelOpen, setQuestionsPanelOpen, selectedGroup, setSelectedGroup, desktop, groupedQuestions }) {
    const [t, i18n] = useTranslation("global");


    useEffect(() => {
        const categoriesEsrsCodes = groupedQuestions.data.map(category => category.esrsCode);
        const subcategorieEsrsCodes = groupedQuestions.data.flatMap(category => category.subcategories.map(sub => sub.esrsCode));

        const initialCategoriesState = categoriesEsrsCodes.reduce((acc, esrsCode, index) => {
            acc[esrsCode] = index === 0;
            return acc;
        }, {});

        const initialSubcategoriesState = subcategorieEsrsCodes.reduce((acc, esrsCode, index) => {
            acc[esrsCode] = index === 0;
            return acc;
        }, {});

        setOpenCategory(initialCategoriesState);
        setOpenSubcategory(initialSubcategoriesState);
        setSelectedGroup(groupedQuestions.data[0].subcategories[0].groups[0]);

    }, [groupedQuestions]);

    const [openCategory, setOpenCategory] = useState([]);
    const [openSubcategory, setOpenSubcategory] = useState([]);

    const toggleScope = (scope) => {
        setOpenCategory(prevState => ({
            ...prevState,
            [scope]: !prevState[scope]
        }));
    };

    const toggleCategory = (category) => {
        setOpenSubcategory(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    const handleButtonClick = (group) => {
        setSelectedGroup(group);
        // setIdQuestion("");
    };


    return (<>
        {!questionsPanelOpen.open &&
            <span className='italic text-xs flex items-center ' id='footprint-description-section'
                onClick={() => setQuestionsPanelOpen({ open: !questionsPanelOpen.open, fixed: true })}>
                <div className='flex pb-2 pl-1 gap-1 items-center'>
                    {desktop && <button onClick={() => setQuestionsPanelOpen({ open: !questionsPanelOpen.open, fixed: true })}
                        onMouseEnter={() => !questionsPanelOpen.open && setQuestionsPanelOpen({ open: !questionsPanelOpen.open, fixed: false })}
                        className="flex flex-row items-center"
                    >
                        <p className="text-xs pl-1" style={{ writingMode: "vertical-rl", transform: "rotate(180deg)", whiteSpace: "nowrap" }}>
                            {t("footPrint.datosDeConsumo")}
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-right"
                            width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M7 7l5 5l-5 5" />
                            <path d="M13 7l5 5l-5 5" />
                        </svg>

                    </button>}
                    {!desktop &&
                        <span className='font-bold text-sm flex items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-right"
                                width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M7 7l5 5l-5 5" />
                                <path d="M13 7l5 5l-5 5" />
                            </svg>
                            {t("footPrint.datosDeConsumo")}</span>
                    }
                </div>
            </span>
        }
        {questionsPanelOpen.open &&
            <div id='footprint-menu' className='pt-2  bg-white border sidebar-airco2 relative'
                style={{ width: desktop ? '15%' : '100%', height: desktop ? '100%' : '', overflowY: desktop ? 'scroll' : 'hidden' }}
                onMouseLeave={() => { questionsPanelOpen.open && !questionsPanelOpen.fixed && setQuestionsPanelOpen({ open: !questionsPanelOpen.open, fixed: false }) }}
                onClick={() => { questionsPanelOpen.open && !questionsPanelOpen.fixed && setQuestionsPanelOpen({ open: true, fixed: true }) }}>

                {desktop && <div className='italic text-xs flex items-center justify-start mr-2 fixed z-10 pl-2' id='footprint-description-section'>
                    <button onClick={() => setQuestionsPanelOpen({ open: !questionsPanelOpen.open, fixed: false })} >

                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-left" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M11 7l-5 5l5 5" />
                            <path d="M17 7l-5 5l5 5" />
                        </svg>
                    </button>
                </div>}

                {!desktop &&
                    <div className='flex pb-2 pl-1 gap-1 items-center'>
                        <button onClick={() => setQuestionsPanelOpen({ open: !questionsPanelOpen.open, fixed: false })}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-left" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M11 7l-5 5l5 5" />
                                <path d="M17 7l-5 5l5 5" />
                            </svg>
                        </button>
                        <span className='font-bold text-sm'>{t("footPrint.datosDeConsumo")}</span>
                    </div>
                }

                <ul className='footprint-menu-vertical  pt-4' style={{ width: '100%' }}>
                    {desktop &&
                        groupedQuestions && groupedQuestions.data.map(category => (
                            <li key={category.esrsCode} className='mb-3 pt-1 pb-1' style={{ borderBottom: "1px solid lightgrey" }}>
                                <div
                                    className=" font-bold text-md pl-1 cursor-pointer ml-2"
                                    onClick={() => toggleScope(category.esrsCode)}
                                >
                                    <span className='flex items-center gap-1 justify-between'>
                                        <span>{category.name} </span>
                                        {openCategory[category.esrsCode] ?
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-up" width="30" height="30" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M6 15l6 -6l6 6" />
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-down" width="30" height="30" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M6 9l6 6l6 -6" />
                                            </svg>
                                        }
                                    </span>
                                </div>
                                {openCategory[category.esrsCode] && (
                                    <ol>
                                        {category.subcategories.map(subcategory => (
                                            <li key={subcategory.esrsCode}
                                                className="py-2 text-sm">
                                                <div
                                                    className=" font-bold pl-1 cursor-pointer ml-2"
                                                    onClick={() => toggleCategory(subcategory.esrsCode)}
                                                >
                                                    <span className='flex items-center gap-1 justify-between pr-1 '>
                                                        <div className='flex items-center gap-1'>
                                                            {/* <span dangerouslySetInnerHTML={{ __html: subcategory.name }} /> */}
                                                            <span> {subcategory.name} </span></div>
                                                        {openSubcategory[subcategory.esrsCode] ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ minWidth: "24px" }} width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path d="M5 12l14 0"></path>
                                                            </svg>
                                                            :
                                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ minWidth: "24px" }} width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path d="M12 5l0 14"></path>
                                                                <path d="M5 12l14 0"></path>
                                                            </svg>
                                                        }
                                                    </span>
                                                </div>
                                                {openSubcategory[subcategory.esrsCode] && (<ol>
                                                    {subcategory.groups.map(group => (
                                                        <li
                                                            key={group.name}
                                                            className={`my-1 py-2 pr-1 pl-4  italic 
                                            ${group.name === selectedGroup?.name ? 'bg-gray-200' : 'bg-white'}`}
                                                            onClick={() => { handleButtonClick(group); if (!desktop) { setQuestionsPanelOpen({ open: !questionsPanelOpen.open, fixed: false }) } }}

                                                        ><button className='w-full flex items-center gap-1'>
                                                                <div style={{ minWidth: "20px" }} >
                                                                    {group.hasAnswers && (<span style={{ color: "var(--airco2-green-color)" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            className="icon icon-tabler icon-tabler-circle-check-filled" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentcolor" fill="var(--airco2-green-color)" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" strokeWidth="0" />
                                                                        </svg></span>
                                                                    )}
                                                                </div>
                                                                <span className="flex text-left text-xs">
                                                                    {group.name}
                                                                </span>
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ol>
                                                )}
                                            </li>))}
                                    </ol>
                                )}
                            </li>
                        ))}
                </ul>
            </div>
        }</>)
}